/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */

import { client, client1, clientCall } from "./client";
import { appConfig } from "../config/app-config";
import { CancelToken } from "axios";
import { ProductPermissions } from "../constants/constants";
import jwtDecode from "jwt-decode";
const customerBaseUrl = `https://qa-customer-racpad.rentacenter.com/api`;
const getCurrentUserBaseURL = "https://dev-menu-racpad.rentacenter.com/api";
const QAToken =
  "eyJraWQiOiI3M1BNSjFNQm1ObkVUU3hPZFNqYWVibVRFK0tjZTRvdVwva1ZDT2VNTGgwbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIydm42dGV0MmhxYmpmbW00ZzdrdDFlNWw4NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicmFjXC9yZWFkIiwiYXV0aF90aW1lIjoxNjQwMzU2Mjk3LCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV84TWJPeUVZRzYiLCJleHAiOjE2NDAzNTk4OTcsImlhdCI6MTY0MDM1NjI5NywidmVyc2lvbiI6MiwianRpIjoiNTJmYWE5ODAtOGI2My00YjY4LThjNGItZWU1NzVkYTg2NDc5IiwiY2xpZW50X2lkIjoiMnZuNnRldDJocWJqZm1tNGc3a3QxZTVsODcifQ.p3OshV8TWtWTnoK7mGsNsVgmJOITRQiobGOdOCw2hXc6Iuif2p6O6DXfjz3cZ5E2wYIu21qzXMGpGoAA1K7FBLIxoBD1CpoUNIT0UMKfD28Z-SOusaZURlp-dG-oIeeLsUnJDG4nFiDC5gqbbF3k0ViElx2gZgnYuhLkeJvu4P52i7qsk3qsUA8_hENJgpGV0_knRh4NuN6c2uYmBAa8rR0opB8UJrvUkj_BUQ6h7Qt-aeIH62wRLUEB5Lwjc2MwuQaug1VINQGAU8S-65akIVtC0iSLSaLxIRTkaCWMbPI7CwHblBqc8hW3M9_-WXaQ5r5V6KdqTcrEeKGUbWakxg";
export const getCurrentUser = (): Promise<any> =>
  client("users/current", { method: "GET" }, appConfig.apiUrls.container, true);

export const getNonInvSalesOptions = async (payload) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `payment/noninventorysale/options`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const postAgreementActivity = async (Payload: any) => {
  try {
    const TempAccessToken = QAToken;
    return await clientCall(
      `agreement/activity`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const getCustomerReceipts = async (customerID: any) => {
  try {
    return await clientCall(
      `payment1/customer/receipts/${customerID}`,
      { method: "GET" },
      appConfig.apiUrls.inprogress,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};

export const getTax = async (data: any) => {
  try {
    return await clientCall(
      `calculate/tax`,
      { method: "POST", body: data, },
      appConfig.apiUrls.inprogress,
      QAToken,
      true
    );
  } catch (e: any) {
    return null;
  }
};

export const ReverseCCPayment = async (data: any) => {
  try {
    return await clientCall(
      "payment1/receipt/reverse/cc",
      {
        method: "POST",
        body: data,
      },
      appConfig.apiUrls.inprogress,
      QAToken
    );
  } catch (e: any) {
    return null;
  }
};

export const GetCustomerCoverage = async (customerId: any) => {
  try {
    return await clientCall(
      `customer/${customerId}/${window.sessionStorage.getItem("storeNumber")}`,
      { method: "PUT" },

      appConfig.apiUrls.customers,
      QAToken
    );
  } catch (e: any) {
    return null;
  }
};
export const GetRemoteLoginStatus = async (Payload: any) => {
  return await clientCall(
    `cashmgmt/duo/auth`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.cashmgmt,
    QAToken,
    true
  );
};

export const GetSuggestedAddress = async (payload: any) => {
  try {
    return await clientCall(
      `customer/address/validate`,
      { method: "POST", body: payload },
      appConfig.apiUrls.customers,
      QAToken
    );
  } catch (e: any) {
    return null;
  }
};
export const CreateCustomerInValidPage = async (payload: any) => {
  try {
    const customHeaders = {
      headers: {
        storeNumber:
          window.localStorage.getItem("storeNumber") == undefined ||
            window.localStorage.getItem("storeNumber") == null
            ? window.sessionStorage.getItem("storeNumber")
            : window.localStorage.getItem("storeNumber"),
      },
    };
    return await clientCall(
      `customer/create`,
      { method: "POST", body: payload, customHeaders },

      appConfig.apiUrls.customers,
      QAToken
    );
  } catch (e: any) {
    return null;
  }
};

export const AdjustDueDate = async (AdjustDueDateReq) => {
  return await clientCall(
    `/payment1/details/duedate`,
    { method: "POST", data: AdjustDueDateReq },
    appConfig.apiUrls.inprogress,
    QAToken
  );
};
export const getCurrentInfo = async () => {
  return await clientCall(
    `user/current`,
    { method: "GET" },
    appConfig.apiUrls.menu,
    QAToken,
    true
  );
};
export const GetEmployeeID = async () => {
  return await clientCall(
    `user/current`,

    { method: "GET" },

    appConfig.apiUrls.menu,

    QAToken,

    true
  );
};

export const GetTaxRateConv = async (taxRequest: any) => {
  try {
    const TempAccessToken = QAToken;
    return await clientCall(
      "payment/make-club-payment",
      {
        method: "POST",
        body: taxRequest,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const CoworkerRequired = async (bodyobject: any) => {
  const TempAccessToken = QAToken;

  return await client1(
    `agreement/address/eventId`,

    { method: "POST", body: bodyobject },

    appConfig.apiUrls.agreements,

    TempAccessToken
  );
};

export const GetRole = async (Payload: any) => {
  return await clientCall(
    `menu-and-stores`,

    { method: "POST", body: Payload },

    appConfig.apiUrls.menu,

    QAToken,

    true
  );
};

export const GetStatsStatus = async (payload: any) => {
  return await clientCall(
    `store-details`,
    { method: "POST", body: payload },
    appConfig.apiUrls.coworker,
    QAToken,
    true
  );
};

export const getPinDetails = async (Payload: any) => {
  return await clientCall(
    `coworker`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.coworker,
    QAToken,
    true
  );
};

export const getprofileaccess = async (Payload: any) => {
  return await clientCall(
    `store-access`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.coworker,
    QAToken,
    true
  );
};

export const getsecondfactor = async (Payload: any) => {
  return await clientCall(
    `coworker-authentication`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.coworker,
    QAToken,
    true
  );
};

export const getUserPreferences = (productType: string) =>
  client(
    `users/current/preferences/${productType}`,

    { method: "GET" },

    appConfig.apiUrls.container
  );

export const GetSearchResult = async (payload: any) => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/validate`,
    { method: "POST", body: payload },

    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const GetAgreementById = async (id: any, type: "Y" | "N") => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/${id}/agreements?inActive=${type}`,
    { method: "GET" },
    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const GetCustomerBasicInfo = async (GCID: any) => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/${GCID}/detail`,
    { method: "GET" },
    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const RunDE = async (payload: any) => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/decision-engine`,
    { method: "POST", body: payload },
    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const UpdateAlert = async (payload: any, customerId: any) => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/${customerId}/update-customer-alert`,
    { method: "PUT", body: payload },

    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const GetNODEReason = async () => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/no-decision-reason`,
    { method: "GET" },
    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const UpdateNoDeReason = async (payload: any) => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/${payload.customerId}/information`,
    { method: "PUT", body: payload },

    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const CustomerCoverage = async (
  AgreementId: any,
  customerId: any,
  nextDueDate: any
) => {
  const TempAccessToken = QAToken;
  if (nextDueDate != "") {
    return await clientCall(
      `customer/${customerId}/${window.sessionStorage.getItem(
        "storeNumber"
      )}?agreementId=${AgreementId}&agreementDuedate=${nextDueDate}`,
      { method: "PUT" },

      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } else {
    return await clientCall(
      `customer/${customerId}/${window.sessionStorage.getItem(
        "storeNumber"
      )}?agreementId=${AgreementId}`,
      { method: "PUT" },

      appConfig.apiUrls.customers,
      TempAccessToken
    );
  }
};

export const GetAlert = async (customerId: any) => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/${customerId}/alerts/assign`,
    { method: "GET" },
    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const GetCustomerAlert = async (customerId: any) => {
  const TempAccessToken = QAToken;
  const queryValue = Math.floor(Math.random() * 1000000 + 1);
  return await client1(
    `customer/alerts/${customerId}?alert=${queryValue}`,
    { method: "GET" },
    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const GetLegalSatatus = async (customerId: any) => {
  const TempAccessToken = QAToken;
  return await client1(
    `customer/${customerId}/legal-hold`,
    { method: "GET" },
    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const getmenuandstore = async () => {
  // const jwtToken = await getJWToken();
  const jwtToken =
    "eyJraWQiOiI3ZHFkMHJVa2F4VmI2UElic3JvSHJ2UVgya1pHNWVhUVVpVWltYnQ1U1JFPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoiT0lkeDY5RmZGaUMyN0pIdVY0b2M4ZyIsImN1c3RvbTplbXBsb3llZUlkIjoiMzc3MDAwNCIsInN1YiI6ImIzMzU3YTQ0LWJhYmEtNGExOS1iODIxLTgzMThhNTQ5NmNlOCIsImNvZ25pdG86Z3JvdXBzIjpbInVzLWVhc3QtMV84TWJPeUVZRzZfT2t0YSJdLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImN1c3RvbTpsYXN0TmFtZSI6IkxuYW1lIiwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfOE1iT3lFWUc2IiwiY29nbml0bzp1c2VybmFtZSI6Ik9rdGFfMzc3MDAwNEByZW50YWNlbnRlci5jb20iLCJub25jZSI6IjI2VjV1Q3BKaFZvRmVza3k5RDAtN2JkVGkwanZLc1lnNnBsMW5NVjIwLVYyaWNIUVJ3MGlha2lBYmUzVmRoSk5nU1NOLV9nQlJHZkhiVzBGdnN6cHJZVVVlb0JVWmtMR3QwV2VaNV9jLVNqVzI5UUE1R0Ztc1M3TGdJcUJ6d0JmMEF2cHkteDcxelA1QTdnR1dIUVZKbHR2eGRfVW9vYUQ2eGNzMC1tVkNxOCIsImF1ZCI6IjQ3cjJpaGs5ZmVoY3BuOXQ2NHRoZGJ1MnRsIiwiaWRlbnRpdGllcyI6W3sidXNlcklkIjoiMzc3MDAwNEByZW50YWNlbnRlci5jb20iLCJwcm92aWRlck5hbWUiOiJPa3RhIiwicHJvdmlkZXJUeXBlIjoiU0FNTCIsImlzc3VlciI6Imh0dHA6XC9cL3d3dy5va3RhLmNvbVwvZXhrcWFmazM2ZnR3VWJJVHMwaDciLCJwcmltYXJ5IjoidHJ1ZSIsImRhdGVDcmVhdGVkIjoiMTYwMjc4MjQxNzA4OSJ9XSwiY3VzdG9tOmZpcnN0TmFtZSI6IjM3NzAwMDQiLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTYzODgyNDI5NCwiZXhwIjoxNjM4ODUzMDk0LCJpYXQiOjE2Mzg4MjQyOTQsImVtYWlsIjoicnBhZC1va3RhcHJldmlld0ByZW50YWNlbnRlci5jb20ifQ.AtfI3cQSGZgZWQshfoiznDwEMv-rjoGbwKqJqWSAzt1RYd3E-ekb4758WtCxhR26KgJILoAjK_8FbR6OFMwROtTTxDJZra3nQi8AnXwt9AWScqE3ZKRgOuF3UlAGF26NAhsrYR8afo30AVM_HJhQ1veNvP8Upfm1rokEGPH-Ezv4vt2hgZDz-tA-5tJfDj4Ad9OADltBTA_duGz7WRTI4rv2WIgAJgK1UV3U-TUi6uGQA2WuhQVQQFXFL-jhrNFFhZsEVacaCvCBM8bubIULBO7hVcWqfqrM2iP7YyqCHtm7Bsr1ANJyHfRaokeECTZGY-h6wks0ij1IBg7O0EiZvg";
  const decodedToken: any = jwtDecode(jwtToken);
  const parameters = {
    coworkerId: decodedToken["custom:employeeId"],
  };

  return await client1(
    "menu-and-stores",
    { method: "POST", body: parameters },
    getCurrentUserBaseURL,
    QAToken
  );
};

export const getUserPermissions = (
  productType: string
): Promise<ProductPermissions[]> =>
  client(
    `users/current/permissions/${productType}`,
    { method: "GET" },
    appConfig.apiUrls.container
  );

export const setUserPreferences = (
  productType: string,
  payload: Record<string, any>,
  cancelToken?: CancelToken
) =>
  client(
    `users/current/preferences/${productType}`,
    {
      method: "PATCH",
      body: [payload],
      cancelToken,
    },
    appConfig.apiUrls.container
  );

export const getUserStores = () => {
  try {
    return client(
      "users/current/stores?q=permissions[:includes]=RACPAD_DAP",
      { method: "GET" },
      appConfig.apiUrls.container
    );
  } catch (err: any) {
    return "Error";
  }
};

export const getpaymentinfo = async (payload: any) => {
  const TempAccessToken = QAToken;
  try {
    return await client1(
      `payment/payment-info/${payload.customerID}`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const calculatePaymentModule = async (payload: any) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `payment/calculate/module`,
      {
        method: "POST",
        data: { apiCall: 'Y', ...payload },
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const MakePayment = async (payload) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `payment/make-payment`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const PlaceOrderCO = async (payload) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `payment/placeorderco`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }

}

export const getPriceQuote = async (Payload: any) => {
  return await clientCall(
    `agreement/price/quote`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.agreements,
    QAToken,
    true
  );
};

export const ProcessPaymentAPI = async (payload) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `payment/take-payment`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const Receiptpdfgen = async (payload) => {

  const TempAccessToken = QAToken;
  // const pdfReq = {
  //   receiptId: ReceiptId,
  //   agreementId: agreementId == null ? undefined : agreementId
  // }
  try {
    return await clientCall(
      `payment/receipt-pdf`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const ReceiptGeneration = async (payload) => {

  const TempAccessToken = QAToken;
  // const pdfReq = {
  //   receiptId: ReceiptId,
  //   agreementId: agreementId == null ? undefined : agreementId
  // }
  try {
    return await clientCall(
      `payment/receipt-pdf`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const postReversePayment = async (postData: any) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      "payment1/receipt/reverse",
      { method: "POST", data: postData },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const Deletecustomercard = (
  paymentMethodId: string,
  customerId: string
) => {
  const TempAccessToken = QAToken;
  try {
    return clientCall(
      `payment/customer-card/delete/${paymentMethodId}/${customerId}`,
      {
        method: "POST",
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const Savecustomercard = (payload) => {
  const TempAccessToken = QAToken;
  try {
    return clientCall(
      `payment/customer-card/save`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const Swipecustomercard = (SwipeCardRequestDTO) => {
  const TempAccessToken = QAToken;
  try {
    return clientCall(
      `payment/customer-card/swipe`,
      {
        method: "POST",
        data: SwipeCardRequestDTO,
      },
      appConfig.apiUrls.paymentalb,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const StoreswipeDevice = (storeDevicePayload) => {
  const TempAccessToken = QAToken;
  try {
    return clientCall(
      `storeSwipeDevicelist/${storeDevicePayload?.storeNumber}?cloudStoreSwipeDevicelist=${storeDevicePayload?.cloudStoreSwipeDevicelist==undefined?'N':storeDevicePayload?.cloudStoreSwipeDevicelist}`,
      {
        method: "GET",
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const GetCardInfo = (CustomerId: string | null) => {
  const TempAccessToken = QAToken;
  try {
    const response: any = client1(
      `payment/customer-card/${CustomerId}`,
      {
        method: "GET",
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
    // Check if paymentMethods is available before filtering
    if (response && response.paymentMethods) {
      // Filter data to exclude records having cardTypeRef as PP, VM, and null
      response.paymentMethods = response.paymentMethods.filter((res: any) => {
        return res.cardTypeRef !== "PP" && res.cardTypeRef !== "VM" && res.cardTypeRef !== null;
      });
    }
    return response;
  } catch (err: any) {
    return "Error";
  }
};
export const Sendtxt2pay = (txt2payReqparam) => {
  const TempAccessToken = QAToken;
  try {
    return client1(
      `payment/send-text`,
      {
        method: "POST",
        data: txt2payReqparam,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const ActiveClub = (clubParam) => {
  const TempAccessToken = QAToken;
  try {
    return client1(
      `customer/activate-club`,
      {
        method: "POST",
        data: clubParam,
      },
      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const LDwServiceCall = (ldwParam) => {
  const TempAccessToken = QAToken;
  try {
    return clientCall(
      `agreement/update/ldw`,
      {
        method: "POST",
        data: ldwParam,
      },
      appConfig.apiUrls.agreements,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const PolicyServiceCall = (policyParam) => {
  const TempAccessToken = QAToken;
  try {
    return clientCall(
      `agreement/policy`,
      {
        method: "POST",
        data: policyParam,
      },
      appConfig.apiUrls.agreements,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const UnEnrollAutoPay = (payload) => {
  const TempAccessToken = QAToken;
  try {
    return client1(
      `payment/autopay-agreement/enroll`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const EnrollAutopay = (payload) => {
  const TempAccessToken = QAToken;
  try {
    return client1(
      `agreement/autopay`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.agreements,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};
// export const Signup=(payload)=>{
//   const TempAccessToken = QAToken;
//   try{
//    return client1(
//     `v1/spa/signup`,
//     {
//       method:'POST',
//       data:payload
//     },
//     `https://dev-essapi.rentacenter.com`,
//     TempAccessToken
//    );
//   }
//   catch(err:any)
//   {
//     return 'Error';
//   }

// }
// export const sentext=(payload)=>{
//   const TempAccessToken=QAToken;
//   try{
//     return client1(
//       `v1/calltxt/sendmessage`,
//       {
//         method:'POST',
//         data:payload
//       },
//       `https://dev-calltxt.rentacenter.com`,
//       TempAccessToken
//     );
//   }
//   catch(err:any)
//   {
//     return 'Error';
//   }
// }
export const sendMessage = (payload) => {
  const TempAccessToken = QAToken;
  try {
    return client1(
      `autopaySendMessage`,
      {
        method: "POST",
        data: payload,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

export const AcceptPaymentApi = (AcceptpaymentRes) => {
  const TempAccessToken = QAToken;
  try {
    return clientCall(
      `payment/accept`,
      {
        method: "POST",
        data: AcceptpaymentRes,
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch {
    return "Error";
  }
};

export const GetState = async () => {
  const TempAccessToken = QAToken;
  return await clientCall(
    `customer/state`,
    { method: "GET" },
    appConfig.apiUrls.customers,
    TempAccessToken
  );
};

export const AgreementCustomer = async (AgreementId) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `agreement/customers/${AgreementId}`,
      { method: "GET" },
      appConfig.apiUrls.agreements,
      TempAccessToken
    );
  } catch {
    return "Error";
  }
};

export const SalesLead = async (salesReqParam) => {
  const TempAccessToken = QAToken;
  try {
    return await clientCall(
      `lead/update`,
      { method: "POST", data: salesReqParam },
      appConfig.apiUrls.dashboard_apiurl,
      TempAccessToken
    );
  } catch {
    return "Error";
  }
};

export const getpaymentHistory = async (customer_id: string, type: string, offset: any) => {
  const TempAccessToken = QAToken;
  try {
    return await client1(
      `payment1/details/history/${customer_id}?type=${type}&limit=10&offset=${offset}`,
      {
        method: "GET",
      },
      appConfig.apiUrls.inprogress,
      TempAccessToken
    );
  } catch (err: any) {
    return "Error";
  }
};

/* eslint-disable no-console */

export const getAgreementInfo = async (AgreementId: any) => {
  return await clientCall(
    `agreement/${AgreementId}`,
    { method: "GET" },
    appConfig.apiUrls.agreements,
    QAToken,
    true
  );
};

export const sendEPO = async (mail: any) => {
  const TempAccessToken = QAToken;

  return await client1(
    `agreement/epo/mail`,
    { method: "POST", body: mail },
    appConfig.apiUrls.agreements,
    TempAccessToken
  );
};

//GetEpoDetails -- Accept EPO Sid
export const GetEpodetails = async (bodyobj: any) => {
  const TempAccessToken = QAToken;

  return await client1(
    `agreement/epo`,
    { method: "POST", body: bodyobj },
    appConfig.apiUrls.agreements,
    TempAccessToken
  );
};

export const getDocument = (documentId: string, typeId?: string) => {
  return clientCall(
    `documents/${documentId}?type=${typeId}&version=ALL`,
    { method: "GET" },
    appConfig.apiUrls.am
  );
};

export const GetCustomerByIdInfo = async (
  customerId: any,
  storeNumber: any
) => {

  try {
    const TempAccessToken = QAToken;
    return await clientCall(
      `customer/${customerId}`,
      { method: "PUT", body: { storeNumber: storeNumber } },
      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const getPrintDocument = async (payload: any) => {
  return await clientCall(
    `agreement/document/print`,
    { method: "POST", body: payload },
    appConfig.apiUrls.agreements,
    QAToken,
    true
  );
};
export const GenerateAgreementDoc = async (Payload: any) => {
  return await clientCall(
    `agreement/document/generate`,
    { method: "POST", body: Payload },
    appConfig.apiUrls.agreements,
    QAToken,
    true
  );
};
export const GetCustomerInfoOptions = async (storeNumber: any) => {
  try {
    const TempAccessToken = QAToken;

    return await clientCall(
      `customer/customerinfo-options/${storeNumber}`,
      { method: "GET" },
      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const addressValidator = async (payload: any) => {
  try {
    const TempAccessToken = QAToken;

    return await clientCall(
      "customer/address/validate",
      {
        method: "POST",
        body: {
          ...payload,
        },
      },
      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const CellActive = async (payload: any) => {
  return await clientCall(
    `cashmgmt/epay`,

    { method: "POST", body: payload },

    appConfig.apiUrls.cashmgmt,

    QAToken,

    true
  );
};
export const Calculate911Fee = async (payload: any) => {
  return await clientCall(
    `cashmgmt/calculate/fee`,

    { method: "POST", body: payload },

    appConfig.apiUrls.cashmgmt,

    QAToken,

    true
  );
};
export const getCustomerForsalesLead = async (gcid: any) => {
  return await clientCall(
    `customer/${gcid}/detail`,
    { method: "GET" },
    customerBaseUrl,
    QAToken,
    true
  );
};
export const StoreSearch = async (storeNumber: any) => {
  return await clientCall(
    `customer/store-search`,

    {
      method: "POST",
      body: {
        storeNumbers: storeNumber,
      },
    },

    appConfig.apiUrls.customers,

    QAToken,

    true
  );
};

export const GetReference = async (storeNumber: any) => {
  return await clientCall(
    `customer/customerinfo-options/${storeNumber}`,
    { method: "GET" },
    appConfig.apiUrls.customers,
    QAToken,
    true
  );
};

export const GetCashSaleProvider = async () => {
  return await clientCall(
    `cashsale/products`,
    { method: "GET" },
    appConfig.apiUrls.cashmgmt,
    QAToken,
    true
  );
};

//Rental Item Sale
/**RIS_PC_NO_08 For rendering the drop down this service call is made. */
export const getSearchParams = async () => {
  return await client1(
    `inventory/search-params/""`,
    { method: 'GET' },
    appConfig.apiUrls.inventory,
    QAToken,
    true);
};
/**RIS_PC_NO_14  service call is made  */
export const getInventorySearch = async (payload: any) => {
  return await clientCall(
    'inventory/search',
    { method: 'POST', body: payload },
    appConfig.apiUrls.inventory,
    QAToken,
    true
  );
};

/**Primary address service call is made */
export const GetCustomerAddress = async (customerId: any, active: any) => {
  try {
    const TempAccessToken = QAToken;
    return await clientCall(
      `customer/address/${customerId}?active=${active}`,
      { method: 'GET' },
      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
}

/**Primary Address update service call */
export const UpdateAddressInfo = async (payload: any) => {
  try {
    const TempAccessToken = QAToken;
    return await clientCall(
      `customer/address/update`,
      { method: 'POST', body: payload },

      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const getRetentionOffers = async (Payload: any) => {
  try {

    const TempAccessToken = QAToken;
    return await clientCall(
      `retention/offers`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.customers,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};
export const storeException = async (Payload: any) => {
  try {

    const TempAccessToken = QAToken;
    return await clientCall(
      `agreement/store/exception`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.agreements,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const emailReceipts = async (
  customerId: string,
  receiptIds: string[]
): Promise<unknown> =>
  clientCall(
    `payment/email-receipt`,
    {
      method: 'POST',
      body: { customerId, receiptIds },
    },
    appConfig.apiUrls.inprogress
  );
  
export const GetWebLeadDetails = async (Payload: any) => {
  try {
    const TempAccessToken = QAToken;
    return await clientCall(
      `leads`,
      { method: "POST", body: Payload },
      appConfig.apiUrls.dashboard_apiurl,
      TempAccessToken
    );
  } catch (e: any) {
    return null;
  }
};

export const RefundWebLeadAmount = async (payload: any) => {
  try {
    return await client(
      `saleLead/refund`,
      { method: 'POST', body: payload },
      appConfig.apiUrls.customers,
      true
    );
  } catch (e: any) {
    return null;
  }
};

/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/prefer-immediate-return */

/* eslint-disable sonarjs/no-duplicated-branches */

/* eslint-disable use-isnan */

/* eslint-disable prettier/prettier */

/* eslint-disable no-compare-neg-zero */

/* eslint-disable no-console */

/* eslint-disable sonarjs/no-one-iteration-loop */

/* eslint-disable sonarjs/no-use-of-empty-return-value */

/* eslint-disable sonarjs/no-collection-size-mischeck */

/* eslint-disable sonarjs/no-collapsible-if */

// eslint-disable-next-line prettier/prettier

/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable sonarjs/no-redundant-boolean */

/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable prefer-const */

/* eslint-disable sonarjs/no-unused-collection */

/* eslint-disable sonarjs/no-duplicate-string */

/* eslint-disable sonarjs/cognitive-complexity */

/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable no-empty */

/* eslint-disable no-console */

/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-debugger */

import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  RACButton,
  Grid,
  RACModalCard,
  RACTextbox,
  RACDatePicker,
  Modal,
  CircularProgress,
} from "@rentacenter/racstrap";
import { takePaymentStyles } from "../../stylesJS/makeStyles";
import { AdjustDueDate } from "../../api/user";

interface PromotionDetails {
  promotionKey: string;
  promotionValue: string;
}

interface PromotionInfo {
  agreementId: string;
  promotionRate?: string;
  promotionType: string;
  promotionDueDate?: string | null;
  maxWeeksAllowed?: string;
  freeTimeWeeksAllowed?: string;
  numberOfFreeDays?: string;
  referralPromoFreeDays?: string | null;
  promotionDetails?: PromotionDetails[] | null;
}

interface AgreementRates {
  Agreementtype?: string;
  agreementId: string;
  agreementNumber: string;
  agreementRateDetails: AgreementRateDetails;
  currentDueDate: string;
  dueDate: string;
  coverageEndDate: string;
  policyStatus: string;
  taxRate: string;
  lateFee: string;
  lateFeeTax: string;
  sacDate: string;
  epoAmount: string;
  epoTax: string;
  isLdwAllowed: string;
  carryRent: string;
  carryRentTax: string;
  carriedLate?: string;
  carriedLateTax?: string;
  scheduleRefCode: string;
  ldwPercentage: string;
  agreementStatus?: string;
  agreementPromotionInfo?: PromotionInfo;
}
interface AgreementRateDetails {
  perDayRent: string;
  perDayRentTax: string;
  weeklyRent: string;
  weeklyRentTax: string;
  biWeeklyRent: string;
  biWeeklyRentTax: string;
  semiMonthlyRent: string;
  semiMonthlyRentTax: string;
  monthlyRent: string;
  monthlyRentTax: string;
  perDayLdw: string;
  perDayLdwTax: string;
  weeklyLdw: string;
  weeklyLdwTax: string;
  biWeeklyLdw: string;
  biWeeklyLdwTax: string;
  semiMonthlyLdw: string;
  semiMonthlyLdwTax: string;
  monthlyLdw: string;
  monthlyLdwTax: string;
  scheduleRefCode: string;
  perDayPolicy: string;
  perDayPolicyTax: string;
  weeklyPolicy: string;
  weeklyPolicyTax: string;
  biWeeklyPolicy: string;
  biWeeklyPolicyTax: string;
  semiMonthlyPolicy: string;
  semiMonthlyPolicyTax: string;
  monthlyPolicy: string;
  monthlyPolicyTax: string;
  semiMonthRange: string;
}

interface RentCalculationRequest {
  rate: string;
  due_date: Date;
  late_days: number;
  past_due_date: Date;
  schedule: string;
  weekly_rate: string;
  biweekly_rate: string;
  monthly_rate: string;
  semi_monthly_rate: string;
  semimonthcount: number;
  semimonthrange: string | null;
  remainingDays: number;
}

interface CalculationFunction {
  rate: string;
  due_date: Date;
  remaining_days: number;
  current_date: Date;
}

interface AdjustDueDate {
  dueDate?: string;
  amountAdjusted?: string;
  schedule?: string;
  rateResponse: AgreementRates[];
}

interface AdjustDueResponse {
  agreementType: string;
  agreementRate?: string;
  nextDueDate: string;
  currentDueDate: string;
  lateFee?: string;
  ldwAmount?: string;
  policyAmount?: string;
  agreementId?: string;
  agreementNumber: string;
  customerClubId?: string;
  ldwTaxAmount?: string;
  rpayTaxAmount?: string;
  ldwPercentage: string;
  lateFeeTaxAmount?: string;
  policyTaxAmount?: string;
  totalAmount: string;
  totalTax: string;
  carryRent: string;
  carryRentTax: string;
  carriedLate?: string;
  carriedLateTax?: string;
  actualEditedValue: ActualValue | null;
  extensionAmount?: string;
  freeDays?: number;
}

interface ActualValue {
  agreementNumber: string;
  schedule: string;
  actualAgreementRate: string;
  actualLdwRate: string;
  actualPolicyRate: string;
  actualTotalTax: string;
  actualTotalAmount: string;
}

interface RateInfo {
  ldwPercentage: string;
  agreementRate: string;
  agreementRateTax: string;
  ldwAmount: string;
  ldwAmountTax: string;
  policyAmount: string;
  policyAmountTax: string;
  agreementId: string;
  agreementNumber: string;
  lateFee: string;
  lateFeeTax: string;
  currentDueDate: string;
  carryRent: string;
  carryRentTax: string;
}

interface AgreementRateInfo {
  perDayRate: RateInfo[];
  weeklyRate: RateInfo[];
  biWeeklyRate: RateInfo[];
  semiMonthlyRate: RateInfo[];
  monthlyRate: RateInfo[];
}

interface EditScheduleReq {
  Agreementtype?: any;
  schedule: string;
  currentDueDate: string;
  taxRate: string;
  coverageEndDate: string;
  agreementId: string;
  agreementNumber: string;
  lateFee: string;
  lateFeeTax: string;
  epoAmount: string;
  sacDate: string;
  epoTax: string;
  policyStatus: string;
  isLdwAllowed: string;
  carryRent: string;
  carryRentTax: string;
  carriedLate?: string;
  carriedLateTax?: string;
  ldwPercentage: string;
  agreementRateDetails: AgreementRateDetails;
}

interface DueDateCalculation {
  due_date: Date;
  past_due_date: Date;
  semimonthcount: number;
}

interface SemiMonthCount {
  semiMonthCount: number;
  remainingDays: number;
}

const DueDateCalculation = async (
  nextduedate: Date,
  schedule: string,
  semimonthdays: string | null
): Promise<DueDateCalculation> => {
  debugger;
  const currentdate: Date = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Chicago" })
  );
  const current_date = new Date(
    currentdate.getFullYear(),
    currentdate.getMonth(),
    currentdate.getDate()
  );
  let no_of_days_bw: number;
  let semimonthcount = 0;
  const next_due_date = nextduedate;
  let due_date = new Date(next_due_date);
  const past_due_date: Date = new Date(nextduedate);
  if (schedule == "WK") {
    if (current_date < due_date) {
      due_date.setDate(due_date.getDate() + 7);
    } else {
      while (current_date > due_date) {
        due_date.setDate(due_date.getDate() + 7);
      }
      no_of_days_bw = Number(
        (
          (due_date.getTime() - current_date.getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      );
      if (no_of_days_bw <= 3) {
        due_date.setDate(due_date.getDate() + 7);
      }
    }
  } else if (schedule == "BIWK") {
    if (current_date < due_date) {
      due_date.setDate(due_date.getDate() + 14);
    } else {
      while (current_date > due_date) {
        due_date.setDate(due_date.getDate() + 14);
      }
      no_of_days_bw = Number(
        (
          (due_date.getTime() - current_date.getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      );
      if (no_of_days_bw <= 3) {
        due_date.setDate(due_date.getDate() + 14);
      }
      console.log(due_date, "testing due date in bi weekly");
    }
  } else if (schedule == "MON") {
    if (current_date < due_date) {
      const currentDateTemp = new Date(due_date);
      due_date = new Date(
        due_date.getFullYear(),
        due_date.getMonth() + 1,
        due_date.getDate()
      );
      if (
        due_date.getMonth() - currentDateTemp.getMonth() != 1 &&
        due_date.getMonth() - currentDateTemp.getMonth() > 0
      ) {
        due_date = new Date(
          currentDateTemp.getFullYear(),
          currentDateTemp.getMonth() + 2,
          0
        );
      }
    } else {
      while (current_date > due_date) {
        const currentDateTemp = new Date(due_date);
        due_date = new Date(
          due_date.getFullYear(),
          due_date.getMonth() + 1,
          due_date.getDate()
        );
        if (
          due_date.getMonth() - currentDateTemp.getMonth() != 1 &&
          due_date.getMonth() - currentDateTemp.getMonth() > 0
        ) {
          due_date = new Date(
            currentDateTemp.getFullYear(),
            currentDateTemp.getMonth() + 2,
            0
          );
        }
      }

      const no_of_days_bw = Number(
        (
          (due_date.getTime() - current_date.getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      );
      if (no_of_days_bw < 7) {
        const currentDateTemp = new Date(due_date);
        due_date = new Date(
          due_date.getFullYear(),
          due_date.getMonth() + 1,
          due_date.getDate()
        );
        if (
          due_date.getMonth() - currentDateTemp.getMonth() != 1 &&
          due_date.getMonth() - currentDateTemp.getMonth() > 0
        ) {
          due_date = new Date(
            currentDateTemp.getFullYear(),
            currentDateTemp.getMonth() + 2,
            0
          );
        }
      }
    }
  } else {
    const range_temp: any =
      semimonthdays != null || semimonthdays != undefined
        ? semimonthdays
        : "1-15";
    const daysRange: any[] = range_temp != null ? range_temp.split("-") : "";
    const daysRange1 = daysRange[0];
    const daysRange2 = daysRange[1];
    let days_paid: any = daysRange1;
    if (current_date < due_date) {
      const previousDueDate = new Date(due_date);
      if (
        due_date.getDate() != daysRange1 ||
        due_date.getDate() != daysRange2
      ) {
        if (
          due_date.getDate() > daysRange1 &&
          due_date.getDate() < daysRange2
        ) {
          due_date.setDate(daysRange2);
        } else if (due_date.getDate() > daysRange2) {
          due_date = new Date(
            due_date.getFullYear(),
            due_date.getMonth() + 1,
            daysRange1,
            due_date.getHours(),
            due_date.getMinutes(),
            due_date.getSeconds(),
            due_date.getMilliseconds()
          );
        } else if (due_date.getDate() < daysRange1) {
          due_date.setDate(daysRange1);
        }
      }
      no_of_days_bw = Number(
        (
          (due_date.getTime() - previousDueDate.getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      );
      if (no_of_days_bw < 7) {
        if (days_paid >= due_date.getDate()) {
          due_date = new Date(
            due_date.getFullYear(),
            due_date.getMonth(),
            daysRange[1],
            due_date.getHours(),
            due_date.getMinutes(),
            due_date.getSeconds(),
            due_date.getMilliseconds()
          );
          days_paid = daysRange1;
          semimonthcount += 1;
        } else {
          due_date = new Date(
            due_date.getFullYear(),
            due_date.getMonth() + 1,
            daysRange[0],
            due_date.getHours(),
            due_date.getMinutes(),
            due_date.getSeconds(),
            due_date.getMilliseconds()
          );
          days_paid = daysRange2;
          semimonthcount += 1;
        }
      }
    } else {
      if (
        due_date.getDate() != daysRange1 ||
        due_date.getDate() != daysRange2
      ) {
        if (
          due_date.getDate() > daysRange1 &&
          due_date.getDate() < daysRange2
        ) {
          due_date.setDate(daysRange2);
        } else if (due_date.getDate() > daysRange2) {
          due_date = new Date(
            due_date.getFullYear(),
            due_date.getMonth() + 1,
            daysRange1,
            due_date.getHours(),
            due_date.getMinutes(),
            due_date.getSeconds(),
            due_date.getMilliseconds()
          );
        } else if (due_date.getDate() < daysRange1) {
          due_date.setDate(daysRange1);
        }
      }

      while (current_date > due_date) {
        if (days_paid >= due_date.getDate()) {
          due_date = new Date(
            due_date.getFullYear(),
            due_date.getMonth(),
            daysRange[1],
            due_date.getHours(),
            due_date.getMinutes(),
            due_date.getSeconds(),
            due_date.getMilliseconds()
          );
          days_paid = daysRange1;
          semimonthcount += 1;
        } else {
          due_date = new Date(
            due_date.getFullYear(),
            due_date.getMonth() + 1,
            daysRange[0],
            due_date.getHours(),
            due_date.getMinutes(),
            due_date.getSeconds(),
            due_date.getMilliseconds()
          );
          days_paid = daysRange2;
          semimonthcount += 1;
        }
      }

      no_of_days_bw = Number(
        (
          (due_date.getTime() - current_date.getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      );
      if (no_of_days_bw < 7) {
        if (days_paid >= due_date.getDate()) {
          due_date = new Date(
            due_date.getFullYear(),
            due_date.getMonth(),
            daysRange[1],
            due_date.getHours(),
            due_date.getMinutes(),
            due_date.getSeconds(),
            due_date.getMilliseconds()
          );
          days_paid = daysRange1;
          semimonthcount += 1;
        } else {
          due_date = new Date(
            due_date.getFullYear(),
            due_date.getMonth() + 1,
            daysRange[0],
            due_date.getHours(),
            due_date.getMinutes(),
            due_date.getSeconds(),
            due_date.getMilliseconds()
          );
          days_paid = daysRange2;
          semimonthcount += 1;
        }
      }
    }
  }

  return {
    due_date: due_date,
    past_due_date: past_due_date,
    semimonthcount: semimonthcount,
  };
  //  respDto;
};

const EditSchedule = async (
  editScheduleRequest: EditScheduleReq[]
): Promise<any> => {
  debugger;
  const editScheduleResponseArray: any = [];
  const presentDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Chicago" })
  );
  const currentDate = new Date(
    presentDate.getFullYear(),
    presentDate.getMonth(),
    presentDate.getDate()
  );
  for (let i = 0; i < editScheduleRequest.length; i++) {
    let ldw = "0.00";
    let ldwTax = "0.00";
    let policy = "0.00";
    let policyTax = "0.00";
    let carryLdw = "0.00";
    let carryLdwTax = "0.00";
    const range =
      editScheduleRequest[i].schedule != "WK" &&
        editScheduleRequest[i].schedule != "BIWK" &&
        editScheduleRequest[i].schedule != "MON"
        ? editScheduleRequest[i].schedule.replace("SEMI", "")
        : null;
    const lateFee = editScheduleRequest[i].lateFee;
    const lateFeeTax =
      editScheduleRequest[i].lateFeeTax != "undefined"
        ? editScheduleRequest[i].lateFeeTax
        : "0.00";
    const carryRent =
      editScheduleRequest[i].carryRent != "undefined"
        ? editScheduleRequest[i].carryRent
        : "0.00";
    const carryRentTax =
      editScheduleRequest[i].carryRentTax != "undefined"
        ? editScheduleRequest[i].carryRentTax
        : "0.00";
    const carriedLate = editScheduleRequest[i].carriedLate;
    const carriedLateTax = editScheduleRequest[i].carriedLateTax;
    const agreementDetails = editScheduleRequest[i].agreementRateDetails;
    const dueDateResponse = await DueDateCalculation(
      new Date(editScheduleRequest[i].currentDueDate),
      editScheduleRequest[i].schedule,
      range
    );
    console.log(dueDateResponse, "testing due date response");
    const daysBw =
      Number(
        (
          (new Date(dueDateResponse.due_date).getTime() -
            new Date(editScheduleRequest[i].currentDueDate).getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      ) || 0.0;
    const semiMonthRange =
      editScheduleRequest[i].schedule != "WK" &&
        editScheduleRequest[i].schedule != "BIWK" &&
        editScheduleRequest[i].schedule != "MON"
        ? editScheduleRequest[i].schedule.replace("SEMI", "")
        : "1-15";
    const semiMonthCount = await countOfSemimonths(
      new Date(dueDateResponse.due_date),
      new Date(editScheduleRequest[i].currentDueDate),
      semiMonthRange,
      1
    );
    debugger;
    const rentCalculationReq: RentCalculationRequest = {
      rate: "0.00",
      due_date: new Date(dueDateResponse.due_date),
      late_days: daysBw,
      past_due_date: new Date(editScheduleRequest[i].currentDueDate),
      schedule: editScheduleRequest[i].schedule,
      weekly_rate: agreementDetails.weeklyRent,
      biweekly_rate: agreementDetails.biWeeklyRent,
      monthly_rate: agreementDetails.monthlyRent,
      semi_monthly_rate: agreementDetails.semiMonthlyRent,
      semimonthcount: semiMonthCount.semiMonthCount,
      semimonthrange: semiMonthRange,
      remainingDays: semiMonthCount.remainingDays,
    };
    const rent = await RentCalculation(rentCalculationReq);
    const renTaxCalculationReq: RentCalculationRequest = {
      rate: "0.00",
      due_date: new Date(dueDateResponse.due_date),
      late_days: daysBw,
      past_due_date: new Date(editScheduleRequest[i].currentDueDate),
      schedule: editScheduleRequest[i].schedule,
      weekly_rate: agreementDetails.weeklyRentTax,
      biweekly_rate: agreementDetails.biWeeklyRentTax,
      monthly_rate: agreementDetails.monthlyRentTax,
      semi_monthly_rate: agreementDetails.semiMonthlyRentTax,
      semimonthcount: semiMonthCount.semiMonthCount,
      semimonthrange: semiMonthRange,
      remainingDays: semiMonthCount.remainingDays,
    };
    const rentTax = editScheduleRequest[i].Agreementtype != 'Note Payment' ? await RentCalculation(renTaxCalculationReq) : '0.00';
    if (new Date(editScheduleRequest[i].coverageEndDate) > currentDate && editScheduleRequest[i].Agreementtype != 'Note Payment') {
      carryLdw =
        editScheduleRequest[i].carryRent != "undefined" &&
          editScheduleRequest[i].ldwPercentage != "undefined" && editScheduleRequest[i].ldwPercentage
          ? (
            Number(editScheduleRequest[i].carryRent) *
            Number(editScheduleRequest[i].ldwPercentage)
          ).toFixed(2)
          : "0.00";
      carryLdwTax =
        Number(carryLdw) > 0
          ? (
            Number(carryLdw) * Number(editScheduleRequest[i].ldwPercentage)
          ).toFixed(2)
          : "0.00";
      const ldwCalculationReq: RentCalculationRequest = {
        rate: "0.00",
        due_date: new Date(dueDateResponse.due_date),
        late_days: daysBw,
        past_due_date: new Date(editScheduleRequest[i].currentDueDate),
        schedule: editScheduleRequest[i].schedule,
        weekly_rate: agreementDetails.weeklyLdw,
        biweekly_rate: agreementDetails.biWeeklyLdw,
        monthly_rate: agreementDetails.monthlyLdw,
        semi_monthly_rate: agreementDetails.semiMonthlyLdw,
        semimonthcount: semiMonthCount.semiMonthCount,
        semimonthrange: semiMonthRange,
        remainingDays: semiMonthCount.remainingDays,
      };
      ldw = await RentCalculation(ldwCalculationReq);
      const ldwTaxCalculationReq: RentCalculationRequest = {
        rate: "0.00",
        due_date: new Date(dueDateResponse.due_date),
        late_days: daysBw,
        past_due_date: new Date(editScheduleRequest[i].currentDueDate),
        schedule: editScheduleRequest[i].schedule,
        weekly_rate: agreementDetails.weeklyLdwTax,
        biweekly_rate: agreementDetails.biWeeklyLdwTax,
        monthly_rate: agreementDetails.monthlyLdwTax,
        semi_monthly_rate: agreementDetails.semiMonthlyLdwTax,
        semimonthcount: semiMonthCount.semiMonthCount,
        semimonthrange: semiMonthRange,
        remainingDays: semiMonthCount.remainingDays,
      };
      ldwTax = await RentCalculation(ldwTaxCalculationReq);
    }
    if (editScheduleRequest[i].policyStatus == "A" && editScheduleRequest[i].Agreementtype != 'Note Payment') {
      const policyCalculationReq: RentCalculationRequest = {
        rate: "0.00",
        due_date: new Date(dueDateResponse.due_date),
        late_days: daysBw,
        past_due_date: new Date(editScheduleRequest[i].currentDueDate),
        schedule: editScheduleRequest[i].schedule,
        weekly_rate: agreementDetails.weeklyPolicy,
        biweekly_rate: agreementDetails.biWeeklyPolicy,
        monthly_rate: agreementDetails.monthlyPolicy,
        semi_monthly_rate: agreementDetails.semiMonthlyPolicy,
        semimonthcount: semiMonthCount.semiMonthCount,
        semimonthrange: semiMonthRange,
        remainingDays: semiMonthCount.remainingDays,
      };
      policy = await RentCalculation(policyCalculationReq);
      const policyTaxCalculationReq: RentCalculationRequest = {
        rate: "0.00",
        due_date: new Date(dueDateResponse.due_date),
        late_days: daysBw,
        past_due_date: new Date(editScheduleRequest[i].currentDueDate),
        schedule: editScheduleRequest[i].schedule,
        weekly_rate: agreementDetails.weeklyPolicyTax,
        biweekly_rate: agreementDetails.biWeeklyPolicyTax,
        monthly_rate: agreementDetails.monthlyPolicyTax,
        semi_monthly_rate: agreementDetails.semiMonthlyPolicyTax,
        semimonthcount: semiMonthCount.semiMonthCount,
        semimonthrange: semiMonthRange,
        remainingDays: semiMonthCount.remainingDays,
      };
      policyTax = await RentCalculation(policyTaxCalculationReq);
    }

    const actualAgreementRate =
      editScheduleRequest[i].schedule == "WK"
        ? agreementDetails.weeklyRent
        : editScheduleRequest[i].schedule == "BIWK"
          ? agreementDetails.biWeeklyRent
          : editScheduleRequest[i].schedule == "MON"
            ? agreementDetails.monthlyRent
            : agreementDetails.semiMonthlyRent;
    const actualLdwRate =
      new Date(editScheduleRequest[i].coverageEndDate) > currentDate
        ? editScheduleRequest[i].schedule == "WK"
          ? agreementDetails.weeklyLdw
          : editScheduleRequest[i].schedule == "BIWK"
            ? agreementDetails.biWeeklyLdw
            : editScheduleRequest[i].schedule == "MON"
              ? agreementDetails.monthlyLdw
              : agreementDetails.semiMonthlyLdw
        : "0.00";
    const actualPolicyRate =
      editScheduleRequest[i].policyStatus == "A"
        ? editScheduleRequest[i].schedule == "WK"
          ? agreementDetails.weeklyPolicy
          : editScheduleRequest[i].schedule == "BIWK"
            ? agreementDetails.biWeeklyPolicy
            : editScheduleRequest[i].schedule == "MON"
              ? agreementDetails.monthlyPolicy
              : agreementDetails.semiMonthlyPolicy
        : "0.00";
    const actualAgreementRateTax =
      editScheduleRequest[i].schedule == "WK"
        ? agreementDetails.weeklyRentTax
        : editScheduleRequest[i].schedule == "BIWK"
          ? agreementDetails.biWeeklyRentTax
          : editScheduleRequest[i].schedule == "MON"
            ? agreementDetails.monthlyRentTax
            : agreementDetails.semiMonthlyRentTax;
    const actualLdwRateTax =
      new Date(editScheduleRequest[i].coverageEndDate) > currentDate
        ? editScheduleRequest[i].schedule == "WK"
          ? agreementDetails.weeklyLdwTax
          : editScheduleRequest[i].schedule == "BIWK"
            ? agreementDetails.biWeeklyLdwTax
            : editScheduleRequest[i].schedule == "MON"
              ? agreementDetails.monthlyLdwTax
              : agreementDetails.semiMonthlyLdwTax
        : "0.00";
    const actualPolicyRateTax =
      editScheduleRequest[i].policyStatus == "A"
        ? editScheduleRequest[i].schedule == "WK"
          ? agreementDetails.weeklyPolicyTax
          : editScheduleRequest[i].schedule == "BIWK"
            ? agreementDetails.biWeeklyPolicyTax
            : editScheduleRequest[i].schedule == "MON"
              ? agreementDetails.monthlyPolicyTax
              : agreementDetails.semiMonthlyPolicyTax
        : "0.00";
    const actualTotalTax = editScheduleRequest[i].Agreementtype != 'Note Payment' ? (
      Number(actualAgreementRateTax) +
      Number(actualLdwRateTax) +
      Number(actualPolicyRateTax)
    ).toFixed(2) : '0.00';
    const actualTotalRate = editScheduleRequest[i].Agreementtype != 'Note Payment' ? (
      Number(actualAgreementRate) +
      Number(actualLdwRate) +
      Number(actualPolicyRate)
    ).toFixed(2) : Number(actualAgreementRate);

    const actualEditedValue: ActualValue = {
      agreementNumber: editScheduleRequest[i].agreementNumber,
      schedule: editScheduleRequest[i].schedule,
      actualAgreementRate: actualAgreementRate,
      actualLdwRate: editScheduleRequest[i].Agreementtype != 'Note Payment' ? actualLdwRate : '0.00',
      actualPolicyRate: editScheduleRequest[i].Agreementtype != 'Note Payment' ? actualPolicyRate : '0.00',
      actualTotalTax: editScheduleRequest[i].Agreementtype != 'Note Payment' ? actualTotalTax : '0.00',
      actualTotalAmount: (
        Number(actualTotalRate) + Number(actualTotalTax)
      ).toFixed(2),
    };

    const response: AdjustDueResponse = {
      agreementType: editScheduleRequest[i].Agreementtype ? editScheduleRequest[i].Agreementtype : "Rental Agreement (RTO)",
      agreementRate: rent,
      nextDueDate: await ConvertDate(dueDateResponse.due_date),
      currentDueDate: await ConvertDate(
        new Date(editScheduleRequest[i].currentDueDate)
      ),
      lateFee: editScheduleRequest[i].lateFee,
      ldwAmount: ldw ? (Number(ldw) + Number(carryLdw)).toFixed(2) : ldw,
      policyAmount: policy,
      agreementId: editScheduleRequest[i].agreementId,
      ldwTaxAmount: ldwTax ? (Number(ldwTax) + Number(carryLdwTax)).toFixed(2) : ldwTax,
      rpayTaxAmount: rentTax,
      lateFeeTaxAmount: editScheduleRequest[i].lateFeeTax,
      policyTaxAmount: policyTax,
      totalAmount: (
        Number(rent) +
        Number(ldw) +
        Number(policy) +
        Number(lateFee) +
        Number(carryRent) +
        Number(carryLdw)
      ).toFixed(2),
      totalTax: (
        Number(rentTax) +
        Number(ldwTax) +
        Number(policyTax) +
        Number(lateFeeTax) +
        Number(carryRentTax) +
        Number(carryLdwTax)
      ).toFixed(2),
      agreementNumber: editScheduleRequest[i].agreementNumber,
      carryRent: carryRent,
      carryRentTax: carryRentTax,
      carriedLate: carriedLate,
      carriedLateTax: carriedLateTax,
      actualEditedValue: actualEditedValue,
      ldwPercentage: ""
    };
    const totalAmount =
      Number(response.agreementRate) + Number(response.rpayTaxAmount);
    const epoAmount = (
      Number(editScheduleRequest[i].epoAmount) -
      Number(editScheduleRequest[i].epoTax)
    ).toFixed(2);
    debugger;
    if (Number(epoAmount) > 0 && totalAmount >= Number(epoAmount)) {
      if (new Date(editScheduleRequest[i].sacDate) >= currentDate) {
        response.nextDueDate = "SAC";
      } else {
        response.nextDueDate = "EPO";
      }
      response.agreementType = editScheduleRequest[i].Agreementtype != 'Note Payment' ? "EPO Rent": "EPO Note";
      response.totalAmount = epoAmount;
      response.totalTax = editScheduleRequest[i].epoTax;
      response.agreementRate = epoAmount;
      response.rpayTaxAmount = editScheduleRequest[i].epoTax;
      response.ldwAmount =
        Number(editScheduleRequest[i].isLdwAllowed) == 1
          ? response.ldwAmount
          : "0.00";
      response.ldwTaxAmount =
        Number(editScheduleRequest[i].isLdwAllowed) == 1
          ? response.ldwTaxAmount
          : "0.00";
    }
    editScheduleResponseArray.push(response);
    console.log(editScheduleResponseArray, "testing editScheduleResponseArray");
  }
  debugger;
  return editScheduleResponseArray;
};

const calculateAgreementPromotion = async (
  req: any,
  amountResponse: AdjustDueResponse,
  nextDueDate: string,
  agreementDetails: AgreementRates
): Promise<any> => {
  debugger;
  console.log("logger inside calculateAgreementPromotion", req);

  let rent = "0.00";
  let rentTax = "0.00";
  let ldw = "0.00";
  let ldwTax = "0.00";
  let policy = "0.00";
  let policyTax = "0.00";
  let promoAmt = "0.00";
  let promoTaxAmt = "0.00";
  let extensionAmount = "0.00";
  let extensionAmountTax = "0.00";

  req.promotionRate = (req.promotionRate != undefined || req.promotionRate != null) ? req.promotionRate : '0.00'
  const presentDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Chicago" })
  );
  const NON_SEMI_MONTHLY_SCHEDULE = ["WK", "BIWK", "MON"];
  const nextDate = new Date(nextDueDate);
  // const currentDate = new Date(moment(presentDate).format("YYYY-MM-DD"));
  const currentDate = new Date(
    presentDate.getFullYear(),
    presentDate.getMonth(),
    presentDate.getDate()
  );
  let promotionDueDate = moment(currentDate)
    .add(req.maxWeeksAllowed ? Number(req.maxWeeksAllowed) : 0, "days")
    .toDate();
  const semiMonthRange = !NON_SEMI_MONTHLY_SCHEDULE.includes(
    agreementDetails.agreementRateDetails.scheduleRefCode
  )
    ? agreementDetails.agreementRateDetails.scheduleRefCode.replace("SEMI", "")
    : "1-15";

  console.log(
    promotionDueDate,
    currentDate,
    Math.round(
      (nextDate.getTime() - promotionDueDate.getTime()) / (1000 * 3600 * 24)
    )
  );

  if (req.promotionType == "PAYNFSTY+" || req.promotionType == "PAYNFSTY" || req.promotionType == "PIF_PYXGTY") {
    const semiMonthCount = await countOfSemimonths(
      nextDate,
      promotionDueDate,
      semiMonthRange,
      0
    );
    let updatedPromotionDueDate: any = moment().format("YYYY-MM-DD");
    if (nextDate >= promotionDueDate) {
      updatedPromotionDueDate = promotionDueDate;
    } else {
      updatedPromotionDueDate = nextDate;
    }
    console.log(
      "sxcsdvrtgxf",
      promotionDueDate,
      nextDate,
      updatedPromotionDueDate
    );

    const promoCalculationReq: RentCalculationRequest = {
      rate: "0.00",
      due_date: updatedPromotionDueDate,
      late_days: Math.round(
        (updatedPromotionDueDate.getTime() - currentDate.getTime()) /
        (1000 * 3600 * 24)
      ),
      past_due_date: currentDate,
      schedule: agreementDetails.agreementRateDetails.scheduleRefCode,
      weekly_rate: agreementDetails.agreementRateDetails.weeklyRent,
      biweekly_rate: agreementDetails.agreementRateDetails.biWeeklyRent,
      monthly_rate: agreementDetails.agreementRateDetails.monthlyRent,
      semi_monthly_rate: agreementDetails.agreementRateDetails.semiMonthlyRent,
      semimonthcount: semiMonthCount.semiMonthCount,
      semimonthrange: semiMonthRange,
      remainingDays: semiMonthCount.remainingDays,
    };
    console.log("promoCalculationReqsdavfd", promoCalculationReq);

    debugger;
    const initialRent = await RentCalculation(promoCalculationReq);
    console.log("sxdevfdvwert35", initialRent);

    const RemainingDaysRate = (
      Number(amountResponse.agreementRate) - Number(initialRent)
    ).toFixed(2);

    // const initialRent = Number(amountResponse.agreementRate);
    const totalInitialPayment =
      Number(amountResponse.totalAmount) + Number(amountResponse.totalTax);
    console.log(
      "cdgergf4egerg",
      RemainingDaysRate,
      initialRent,
      totalInitialPayment,
      req.promotionRate,
      promotionDueDate,
      nextDate
    );

    if (Number(initialRent) >= Number(req.promotionRate)) {
      const taxRateSum = 1 + Number(agreementDetails.taxRate);
      promoAmt = Number(req.promotionRate) > 0 ? (Number(req.promotionRate) / taxRateSum).toFixed(2) : '0.00';
      promoTaxAmt = Number(req.promotionRate) > 0 ? (Number(req.promotionRate) - Number(promoAmt)).toFixed(2) : '0.00';
      extensionAmount = (Number(initialRent) - Number(promoAmt)).toFixed(2);
      extensionAmountTax = (
        Number(extensionAmount) * Number(agreementDetails.taxRate)
      ).toFixed(2);
      console.log(promoAmt, extensionAmount, extensionAmountTax, promoTaxAmt);
      rent = (Number(rent) + Number(promoAmt)).toFixed(2);
      rentTax = (Number(rentTax) + Number(promoTaxAmt)).toFixed(2);
    } else {
      rent = initialRent;
      rentTax = (Number(rent) * Number(agreementDetails.taxRate)).toFixed(2);
    }
    console.log(
      "sxcsdvrtgxfdcwf565y4w",
      promotionDueDate,
      nextDate,
      updatedPromotionDueDate
    );

    if (nextDate >= promotionDueDate && promotionDueDate >= currentDate) {
      console.log("sxdwefrs567kuyj8h7g6", rent, rentTax, ldw, ldwTax);

      rent = (Number(rent) + Number(RemainingDaysRate)).toFixed(2);
      rentTax = (
        Number(rentTax) +
        Number(RemainingDaysRate) * Number(agreementDetails.taxRate)
      ).toFixed(2);

      if (
        agreementDetails.coverageEndDate &&
        moment(agreementDetails.coverageEndDate).isAfter(moment(agreementDetails.currentDueDate))
      ) {
        if (Number(totalInitialPayment) > Number(req.promotionRate)) {
          ldw = (
            Math.trunc(
              (Number(ldw) +
                Number(RemainingDaysRate) *
                (agreementDetails.ldwPercentage
                  ? Number(agreementDetails.ldwPercentage)
                  : 0)) *
              100
            ) / 100
          ).toString();
          ldwTax = (Number(ldw) * Number(agreementDetails.taxRate)).toFixed(2);
        } else {
          ldw = (
            Math.trunc(
              Number(amountResponse.agreementRate) *
              (agreementDetails.ldwPercentage
                ? Number(agreementDetails.ldwPercentage)
                : 0) *
              100
            ) / 100
          ).toString();
          ldwTax = (Number(ldw) * Number(agreementDetails.taxRate)).toFixed(2);
        }
      }

    }
    console.log("65465415166", rent, rentTax, ldw, ldwTax);
  }

  const response: AdjustDueResponse = {
    agreementType: "Rental Agreement (RTO)",
    nextDueDate: nextDueDate,
    currentDueDate: "",
    lateFee: "0.00",
    ldwAmount: ldw,
    ldwTaxAmount: ldwTax,
    policyAmount: policy,
    policyTaxAmount: policyTax,
    agreementRate: rent,
    rpayTaxAmount: rentTax,
    lateFeeTaxAmount: "0.00",
    agreementId: agreementDetails.agreementId,
    agreementNumber: agreementDetails.agreementNumber,
    totalAmount: (Number(rent) + Number(ldw) + Number(policy)).toFixed(2),
    totalTax: (Number(rentTax) + Number(ldwTax) + Number(policyTax)).toFixed(2),
    carryRent: "",
    carryRentTax: "",
    carriedLate: "0",
    carriedLateTax: "0",
    extensionAmount: extensionAmount,
    freeDays: Math.ceil(
      Number(extensionAmount) /
      Number(agreementDetails.agreementRateDetails.perDayRent)
    ),
    actualEditedValue: null,
    ldwPercentage: ""
  };
  console.log("responsecsregvrew", response);

  return response;
};

const AdjustDueDatecalci = async (
  adjustDueDateReq: AdjustDueDate
): Promise<any> => {
  const currentDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Chicago" })
  );
  const presentDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );
  let totalCost = "0.00";
  const responseArray: any = [];
  debugger;
  if (
    adjustDueDateReq.schedule != undefined &&
    adjustDueDateReq.schedule != null &&
    adjustDueDateReq.schedule != ""
  ) {
    const editScheduleArray: EditScheduleReq[] = [];
    for (let index = 0; index < adjustDueDateReq.rateResponse.length; index++) {
      const editScheduleRequest: EditScheduleReq = {
        Agreementtype: adjustDueDateReq.rateResponse[index].Agreementtype,
        schedule: adjustDueDateReq.schedule,
        currentDueDate: adjustDueDateReq.rateResponse[index].currentDueDate,
        agreementRateDetails:
          adjustDueDateReq.rateResponse[index].agreementRateDetails,
        taxRate: adjustDueDateReq.rateResponse[index].taxRate,
        coverageEndDate: adjustDueDateReq.rateResponse[index].coverageEndDate,
        lateFee: adjustDueDateReq.rateResponse[index].lateFee,
        lateFeeTax: adjustDueDateReq.rateResponse[index].lateFeeTax,
        agreementId: adjustDueDateReq.rateResponse[index].agreementId,
        epoAmount: adjustDueDateReq.rateResponse[index].epoAmount,
        sacDate: adjustDueDateReq.rateResponse[index].sacDate,
        epoTax: adjustDueDateReq.rateResponse[index].epoTax,
        policyStatus: adjustDueDateReq.rateResponse[index].policyStatus,
        isLdwAllowed: adjustDueDateReq.rateResponse[index].isLdwAllowed,
        agreementNumber: adjustDueDateReq.rateResponse[index].agreementNumber,
        carryRent: adjustDueDateReq.rateResponse[index].carryRent,
        carriedLate: adjustDueDateReq.rateResponse[index].carriedLate,
        carriedLateTax: adjustDueDateReq.rateResponse[index].carriedLateTax,
        carryRentTax: adjustDueDateReq.rateResponse[index].carryRentTax,
        ldwPercentage: adjustDueDateReq.rateResponse[index].ldwPercentage,
      };
      editScheduleArray.push(editScheduleRequest);
    }
    const editSchedule = await EditSchedule(editScheduleArray);
    console.log("testing editSchedule", editSchedule);
    return editSchedule;
  } else {
    if (adjustDueDateReq.dueDate != undefined) {
      for (let i = 0; i < adjustDueDateReq.rateResponse.length; i++) {
        let ldw = "0.00";
        let ldwTax = "0.00";
        let policy = "0.00";
        let policyTax = "0.00";
        let carryLdw = "0.00";
        let carryLdwTax = "0.00";
        const agreementRent = adjustDueDateReq.rateResponse[i];
        const lateFee = agreementRent.lateFee;
        const lateFeeTax =
          agreementRent.lateFeeTax != "undefined"
            ? agreementRent.lateFeeTax
            : "0.00";
        const carryRent =
          agreementRent.carryRent != "undefined"
            ? agreementRent.carryRent
            : "0.00";
        const carryRentTax =
          agreementRent.carryRentTax != "undefined"
            ? agreementRent.carryRentTax
            : "0.00";
        const carriedLate = agreementRent.carriedLate;
        const carriedLateTax = agreementRent.carriedLateTax;
        const daysBw =
          Number(
            (
              (new Date(adjustDueDateReq.dueDate).getTime() -
                new Date(agreementRent.currentDueDate).getTime()) /
              (1000 * 3600 * 24)
            ).toFixed()
          ) || 0.0;
        const semiMonthRange =
          adjustDueDateReq.rateResponse[i].scheduleRefCode != "WK" &&
            adjustDueDateReq.rateResponse[i].scheduleRefCode != "BIWK" &&
            adjustDueDateReq.rateResponse[i].scheduleRefCode != "MON"
            ? adjustDueDateReq.rateResponse[i].scheduleRefCode.replace(
              "SEMI",
              ""
            )
            : "1-15";
        const semiMonthCount = await countOfSemimonths(
          new Date(adjustDueDateReq.dueDate),
          new Date(agreementRent.currentDueDate),
          semiMonthRange,
          0
        );

        const rentCalculationReq: RentCalculationRequest = {
          rate: "0.00",
          due_date: new Date(adjustDueDateReq.dueDate),
          late_days: daysBw,
          past_due_date: new Date(agreementRent.currentDueDate),
          schedule: adjustDueDateReq.rateResponse[i].scheduleRefCode,
          weekly_rate: agreementRent.agreementRateDetails.weeklyRent,
          biweekly_rate: agreementRent.agreementRateDetails.biWeeklyRent,
          monthly_rate: agreementRent.agreementRateDetails.monthlyRent,
          semi_monthly_rate: agreementRent.agreementRateDetails.semiMonthlyRent,
          semimonthcount: semiMonthCount.semiMonthCount,
          semimonthrange: semiMonthRange,
          remainingDays: semiMonthCount.remainingDays,
        };
        const rent = await RentCalculation(rentCalculationReq);
        const renTaxCalculationReq: RentCalculationRequest = {
          rate: "0.00",
          due_date: new Date(adjustDueDateReq.dueDate),
          late_days: daysBw,
          past_due_date: new Date(agreementRent.currentDueDate),
          schedule: adjustDueDateReq.rateResponse[i].scheduleRefCode,
          weekly_rate: agreementRent.agreementRateDetails.weeklyRentTax,
          biweekly_rate: agreementRent.agreementRateDetails.biWeeklyRentTax,
          monthly_rate: agreementRent.agreementRateDetails.monthlyRentTax,
          semi_monthly_rate:
            agreementRent.agreementRateDetails.semiMonthlyRentTax,
          semimonthcount: semiMonthCount.semiMonthCount,
          semimonthrange: semiMonthRange,
          remainingDays: semiMonthCount.remainingDays,
        };
        const rentTax = adjustDueDateReq.rateResponse[i].Agreementtype != 'Note Payment' ? await RentCalculation(renTaxCalculationReq) : "0.00";
        // const coverageEndDate = agreementRent.coverageEndDate != null ? new Date(agreementRent.coverageEndDate) : new Date();
        if (new Date(agreementRent.coverageEndDate) > currentDate && adjustDueDateReq.rateResponse[i].Agreementtype != 'Note Payment') {
          carryLdw =
            agreementRent.carryRent != "undefined" &&
              agreementRent.ldwPercentage != "undefined" && agreementRent.ldwPercentage
              ? (
                Number(agreementRent.carryRent) *
                Number(agreementRent.ldwPercentage)
              ).toFixed(2)
              : "0.00";
          carryLdwTax =
            Number(carryLdw) > 0
              ? (
                Number(carryLdw) * Number(agreementRent.ldwPercentage)
              ).toFixed(2)
              : "0.00";
          const ldwCalculationReq: RentCalculationRequest = {
            rate: "0.00",
            due_date: new Date(adjustDueDateReq.dueDate),
            late_days: daysBw,
            past_due_date: new Date(agreementRent.currentDueDate),
            schedule: adjustDueDateReq.rateResponse[i].scheduleRefCode,
            weekly_rate: agreementRent.agreementRateDetails.weeklyLdw,
            biweekly_rate: agreementRent.agreementRateDetails.biWeeklyLdw,
            monthly_rate: agreementRent.agreementRateDetails.monthlyLdw,
            semi_monthly_rate:
              agreementRent.agreementRateDetails.semiMonthlyLdw,
            semimonthcount: semiMonthCount.semiMonthCount,
            semimonthrange: semiMonthRange,
            remainingDays: semiMonthCount.remainingDays,
          };
          ldw = await RentCalculation(ldwCalculationReq);
          const ldwTaxCalculationReq: RentCalculationRequest = {
            rate: "0.00",
            due_date: new Date(adjustDueDateReq.dueDate),
            late_days: daysBw,
            past_due_date: new Date(agreementRent.currentDueDate),
            schedule: adjustDueDateReq.rateResponse[i].scheduleRefCode,
            weekly_rate: agreementRent.agreementRateDetails.weeklyLdwTax,
            biweekly_rate: agreementRent.agreementRateDetails.biWeeklyLdwTax,
            monthly_rate: agreementRent.agreementRateDetails.monthlyLdwTax,
            semi_monthly_rate:
              agreementRent.agreementRateDetails.semiMonthlyLdwTax,
            semimonthcount: semiMonthCount.semiMonthCount,
            semimonthrange: semiMonthRange,
            remainingDays: semiMonthCount.remainingDays,
          };
          ldwTax = await RentCalculation(ldwTaxCalculationReq);
        }
        if (agreementRent.policyStatus == "A" && adjustDueDateReq.rateResponse[i].Agreementtype != 'Note Payment') {
          const policyCalculationReq: RentCalculationRequest = {
            rate: "0.00",
            due_date: new Date(adjustDueDateReq.dueDate),
            late_days: daysBw,
            past_due_date: new Date(agreementRent.currentDueDate),
            schedule: adjustDueDateReq.rateResponse[i].scheduleRefCode,
            weekly_rate: agreementRent.agreementRateDetails.weeklyPolicy,
            biweekly_rate: agreementRent.agreementRateDetails.biWeeklyPolicy,
            monthly_rate: agreementRent.agreementRateDetails.monthlyPolicy,
            semi_monthly_rate:
              agreementRent.agreementRateDetails.semiMonthlyPolicy,
            semimonthcount: semiMonthCount.semiMonthCount,
            semimonthrange: semiMonthRange,
            remainingDays: semiMonthCount.remainingDays,
          };
          policy = await RentCalculation(policyCalculationReq);
          const policyTaxCalculationReq: RentCalculationRequest = {
            rate: "0.00",
            due_date: new Date(adjustDueDateReq.dueDate),
            late_days: daysBw,
            past_due_date: new Date(agreementRent.currentDueDate),
            schedule: adjustDueDateReq.rateResponse[i].scheduleRefCode,
            weekly_rate: agreementRent.agreementRateDetails.weeklyPolicyTax,
            biweekly_rate: agreementRent.agreementRateDetails.biWeeklyPolicyTax,
            monthly_rate: agreementRent.agreementRateDetails.monthlyPolicyTax,
            semi_monthly_rate:
              agreementRent.agreementRateDetails.semiMonthlyPolicyTax,
            semimonthcount: semiMonthCount.semiMonthCount,
            semimonthrange: semiMonthRange,
            remainingDays: semiMonthCount.remainingDays,
          };
          policyTax = await RentCalculation(policyTaxCalculationReq);
        }
        const response: AdjustDueResponse = {
          agreementType: agreementRent.Agreementtype ? agreementRent.Agreementtype : "Rental Agreement (RTO)",
          agreementRate: rent,
          nextDueDate: await ConvertDate(new Date(adjustDueDateReq.dueDate)),
          currentDueDate: await ConvertDate(
            new Date(agreementRent.currentDueDate)
          ),
          lateFee: lateFee,
          ldwAmount: ldw ? (Number(ldw) + Number(carryLdw)).toFixed(2) : ldw,
          policyAmount: policy,
          agreementId: agreementRent.agreementId,
          ldwTaxAmount: ldwTax ? (Number(ldwTax) + Number(carryLdwTax)).toFixed(2) : ldwTax,
          rpayTaxAmount: rentTax,
          lateFeeTaxAmount: lateFeeTax,
          policyTaxAmount: policyTax,
          totalAmount: (
            Number(rent) +
            Number(ldw) +
            Number(policy) +
            Number(lateFee) +
            Number(carryRent) +
            Number(carryLdw)
          ).toFixed(2),
          totalTax: (
            Number(rentTax) +
            Number(ldwTax) +
            Number(policyTax) +
            Number(lateFeeTax) +
            Number(carryRentTax) +
            Number(carryLdwTax)
          ).toFixed(2),
          agreementNumber: agreementRent.agreementNumber,
          carryRent: carryRent,
          carryRentTax: carryRentTax,
          carriedLate: carriedLate,
          carriedLateTax: carriedLateTax,
          actualEditedValue: null,
          ldwPercentage: ""
        };
        const totalAmount =
          Number(response.agreementRate) +
          Number(response.rpayTaxAmount) +
          Number(response.ldwAmount) +
          Number(response.ldwTaxAmount) +
          Number(response.carryRent) +
          Number(response.carryRentTax) +
          Number(carryLdwTax) +
          Number(carryLdw);
        const epoAmount = agreementRent.epoAmount;
        if (Number(epoAmount) > 0 && totalAmount >= Number(epoAmount)) {
          if (new Date(agreementRent.sacDate) >= presentDate) {
            response.nextDueDate = "SAC";
          } else {
            response.nextDueDate = "EPO";
          }
          response.agreementType = adjustDueDateReq.rateResponse[i].Agreementtype != 'Note Payment' ? "EPO Rent": "EPO Note";
          response.totalAmount = (
            Number(agreementRent.epoAmount) - Number(agreementRent.epoTax)
          ).toFixed(2);
          response.totalTax = agreementRent.epoTax;
          response.agreementRate = (
            Number(agreementRent.epoAmount) - Number(agreementRent.epoTax)
          ).toFixed(2);
          response.rpayTaxAmount = agreementRent.epoTax;
          response.ldwAmount = adjustDueDateReq.rateResponse[i].Agreementtype != 'Note Payment' ?
            Number(agreementRent.isLdwAllowed) == 1
              ? response.ldwAmount
              : "0.00" : "0.00";
          response.ldwTaxAmount = adjustDueDateReq.rateResponse[i].Agreementtype != 'Note Payment' ?
            Number(agreementRent.isLdwAllowed) == 1
              ? response.ldwTaxAmount
              : "0.00" : "0.00";
        }
        responseArray.push(response);
        totalCost = (
          Number(totalCost) +
          Number(rent) +
          Number(rentTax) +
          Number(ldw) +
          Number(ldwTax)
        ).toFixed(2);

        if (
          adjustDueDateReq.rateResponse[i].agreementStatus &&
          adjustDueDateReq.rateResponse[i].agreementStatus == "PRELIMINV" &&
          adjustDueDateReq.rateResponse[i].agreementPromotionInfo &&
          (adjustDueDateReq?.rateResponse[i].agreementPromotionInfo?.promotionType == 'PAYNFSTY+' ||
            adjustDueDateReq?.rateResponse[i].agreementPromotionInfo?.promotionType == 'PAYNFSTY' ||
            adjustDueDateReq?.rateResponse[i].agreementPromotionInfo?.promotionType == 'PIF_PYXGTY')
        ) {
          const promitionInfo = adjustDueDateReq.rateResponse[i]
            .agreementPromotionInfo
            ? adjustDueDateReq.rateResponse[i].agreementPromotionInfo
            : [];

          const promoCalculationResp = await calculateAgreementPromotion(
            promitionInfo,
            response,
            adjustDueDateReq.dueDate,
            adjustDueDateReq.rateResponse[0]
          );

          return [promoCalculationResp];
        }
      }
    } else if (adjustDueDateReq.amountAdjusted != undefined) {
      debugger;
      let forwardCalc = 1;
      let adjustDueDateResp: AdjustDueResponse[] = [];
      const agreementRates: AgreementRateInfo = {
        perDayRate: [],
        weeklyRate: [],
        biWeeklyRate: [],
        semiMonthlyRate: [],
        monthlyRate: [],
      };
      let totalMonthlyRate = "0.00";
      let totalSemiMonthlyRate = "0.00";
      let totalWeeklyRate = "0.00";
      let totalBiWeeklyRate = "0.00";
      let totalPerDayRate = "0.00";
      let totalLateFee = "0.00";
      let totalCarryRent = "0.00";
      let agreementSchedule = "";
      let dueDate: Date = new Date();
      let adjustedAmount = adjustDueDateReq.amountAdjusted;
      for (let i = 0; i < adjustDueDateReq.rateResponse.length; i++) {
        const agreementRent = adjustDueDateReq.rateResponse[i];
        agreementSchedule = agreementRent.agreementRateDetails.scheduleRefCode;
        dueDate = new Date(agreementRent.currentDueDate);
        const isLdwActive =
          new Date(agreementRent.coverageEndDate) > currentDate ? 1 : 0;
        const isPolicyActive = agreementRent.policyStatus == "A" ? 1 : 0;

        if (isLdwActive == 1) {
          totalMonthlyRate = (
            Number(totalMonthlyRate) +
            Number(agreementRent.agreementRateDetails.monthlyLdw) +
            Number(agreementRent.agreementRateDetails.monthlyLdwTax)
          ).toString();
          totalSemiMonthlyRate = (
            Number(totalSemiMonthlyRate) +
            Number(agreementRent.agreementRateDetails.semiMonthlyLdw) +
            Number(agreementRent.agreementRateDetails.semiMonthlyLdwTax)
          ).toString();
          totalBiWeeklyRate = (
            Number(totalBiWeeklyRate) +
            Number(agreementRent.agreementRateDetails.biWeeklyLdw) +
            Number(agreementRent.agreementRateDetails.biWeeklyLdwTax)
          ).toString();
          totalWeeklyRate = (
            Number(totalWeeklyRate) +
            Number(agreementRent.agreementRateDetails.weeklyLdw) +
            Number(agreementRent.agreementRateDetails.weeklyLdwTax)
          ).toString();
          totalPerDayRate = (
            Number(totalPerDayRate) +
            Number(agreementRent.agreementRateDetails.perDayLdw) +
            Number(agreementRent.agreementRateDetails.perDayLdwTax)
          ).toString();
        }

        if (isPolicyActive == 1) {
          totalMonthlyRate = (
            Number(totalMonthlyRate) +
            Number(agreementRent.agreementRateDetails.monthlyPolicy) +
            Number(agreementRent.agreementRateDetails.monthlyPolicyTax)
          ).toString();
          totalSemiMonthlyRate = (
            Number(totalSemiMonthlyRate) +
            Number(agreementRent.agreementRateDetails.semiMonthlyPolicy) +
            Number(agreementRent.agreementRateDetails.semiMonthlyPolicyTax)
          ).toString();
          totalBiWeeklyRate = (
            Number(totalBiWeeklyRate) +
            Number(agreementRent.agreementRateDetails.biWeeklyPolicy) +
            Number(agreementRent.agreementRateDetails.biWeeklyPolicyTax)
          ).toString();
          totalWeeklyRate = (
            Number(totalWeeklyRate) +
            Number(agreementRent.agreementRateDetails.weeklyPolicy) +
            Number(agreementRent.agreementRateDetails.weeklyPolicyTax)
          ).toString();
          totalPerDayRate = (
            Number(totalPerDayRate) +
            Number(agreementRent.agreementRateDetails.perDayPolicy) +
            Number(agreementRent.agreementRateDetails.perDayPolicyTax)
          ).toString();
        }

        totalMonthlyRate = (
          Number(totalMonthlyRate) +
          Number(agreementRent.agreementRateDetails.monthlyRent) +
          Number(agreementRent.agreementRateDetails.monthlyRentTax)
        ).toFixed(2);
        totalSemiMonthlyRate = (
          Number(totalSemiMonthlyRate) +
          Number(agreementRent.agreementRateDetails.semiMonthlyRent) +
          Number(agreementRent.agreementRateDetails.semiMonthlyRentTax)
        ).toFixed(2);
        totalBiWeeklyRate = (
          Number(totalBiWeeklyRate) +
          Number(agreementRent.agreementRateDetails.biWeeklyRent) +
          Number(agreementRent.agreementRateDetails.biWeeklyRentTax)
        ).toFixed(2);
        totalWeeklyRate = (
          Number(totalWeeklyRate) +
          Number(agreementRent.agreementRateDetails.weeklyRent) +
          Number(agreementRent.agreementRateDetails.weeklyRentTax)
        ).toFixed(2);
        totalPerDayRate = (
          Number(totalPerDayRate) +
          Number(agreementRent.agreementRateDetails.perDayRent) +
          Number(agreementRent.agreementRateDetails.perDayRentTax)
        ).toFixed(2);

        totalLateFee = (
          Number(totalLateFee) +
          Number(agreementRent.lateFee) +
          Number(
            agreementRent.lateFeeTax != "undefined"
              ? agreementRent.lateFeeTax
              : "0.00"
          )
        ).toFixed(2);

        const carryLdw = Number(
          agreementRent.carryRent != "undefined" &&
            agreementRent.ldwPercentage != "undefined" && agreementRent.ldwPercentage
            ? (
              Number(agreementRent.carryRent) *
              Number(agreementRent.ldwPercentage)
            ).toFixed(2)
            : "0.00"
        );

        const carryLdwTax = Number(
          Number(carryLdw) > 0 && agreementRent.ldwPercentage != "undefined"
            ? (Number(carryLdw) * Number(agreementRent.ldwPercentage)).toFixed(
              2
            )
            : "0.00"
        );

        totalCarryRent = (
          Number(totalCarryRent) +
          Number(
            agreementRent.carryRent != "undefined"
              ? agreementRent.carryRent
              : "0.00"
          ) +
          Number(carryLdw) +
          Number(carryLdwTax) +
          Number(
            agreementRent.carryRentTax != "undefined"
              ? agreementRent.carryRentTax
              : "0.00"
          )
        ).toFixed(2);
        await AgreementScheduleRates(
          agreementRates,
          agreementRent,
          isLdwActive,
          isPolicyActive
        );
      }
      adjustedAmount = (
        Number(adjustedAmount) -
        Number(totalLateFee) -
        Number(totalCarryRent)
      ).toFixed(2);
      if (Number(adjustedAmount) < Number(totalPerDayRate)) {
        // console.log('Entered Amount is less than one day rent');
      } else {
        if (
          agreementSchedule != "WK" &&
          agreementSchedule != "BIWK" &&
          agreementSchedule != "MON"
        ) {
          if (Number(adjustedAmount) >= Number(totalSemiMonthlyRate)) {
            const currentDate = new Date(
              adjustDueDateReq.rateResponse[0].currentDueDate
            );
            let remaining_days = 0;
            const range = agreementSchedule.replace("SEMI", "");
            const daysRange: any[] = range != null ? range.split("-") : [1, 15];
            let days_paid: any = daysRange[0];
            if (daysRange.length > 0) {
              console.log(
                "testing due Date before adjusting in semimonthly function",
                dueDate,
                currentDate
              );
              if (
                currentDate.getDate() != daysRange[0] &&
                currentDate.getDate() != daysRange[1]
              ) {
                if (currentDate.getDate() < daysRange[0]) {
                  dueDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    daysRange[0]
                  );
                  remaining_days = Number(
                    (
                      (dueDate.getTime() - currentDate.getTime()) /
                      (1000 * 3600 * 24)
                    ).toFixed()
                  );
                } else if (
                  currentDate.getDate() > daysRange[0] &&
                  currentDate.getDate() < daysRange[1]
                ) {
                  dueDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    daysRange[1]
                  );
                  remaining_days = Number(
                    (
                      (dueDate.getTime() - currentDate.getTime()) /
                      (1000 * 3600 * 24)
                    ).toFixed()
                  );
                } else if (currentDate.getDate() > daysRange[1]) {
                  dueDate = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth() + 1,
                    daysRange[0]
                  );
                  remaining_days = Number(
                    (
                      (dueDate.getTime() - currentDate.getTime()) /
                      (1000 * 3600 * 24)
                    ).toFixed()
                  );
                }
              }
              console.log(
                "testing dueDate in semimonthly reverse calculation",
                remaining_days,
                dueDate
              );
              while (remaining_days > 0) {
                if (remaining_days >= 14) {
                  adjustedAmount = (
                    Number(adjustedAmount) - Number(totalBiWeeklyRate)
                  ).toFixed(2);
                  // dueDate.setDate(dueDate.getDate() + 14);
                  adjustDueDateResp = await ReverseCalculationResponse(
                    adjustDueDateResp,
                    agreementRates.biWeeklyRate,
                    dueDate.toISOString()
                  );
                  remaining_days -= 14;
                  console.log(
                    "testing remaining days in biweekly",
                    remaining_days,
                    dueDate
                  );
                }
                if (remaining_days >= 7) {
                  adjustedAmount = (
                    Number(adjustedAmount) - Number(totalWeeklyRate)
                  ).toFixed(2);
                  // dueDate.setDate(dueDate.getDate() + 7);
                  adjustDueDateResp = await ReverseCalculationResponse(
                    adjustDueDateResp,
                    agreementRates.weeklyRate,
                    dueDate.toISOString()
                  );
                  remaining_days -= 7;
                  console.log(
                    "testing remaining days in weekly",
                    remaining_days,
                    dueDate
                  );
                }
                if (remaining_days >= 1) {
                  adjustedAmount = (
                    Number(adjustedAmount) - Number(totalPerDayRate)
                  ).toFixed(2);
                  // dueDate.setDate(dueDate.getDate() + 1);
                  adjustDueDateResp = await ReverseCalculationResponse(
                    adjustDueDateResp,
                    agreementRates.perDayRate,
                    dueDate.toISOString()
                  );
                  remaining_days -= 1;
                  console.log(
                    "testing remaining days in perday",
                    remaining_days,
                    dueDate,
                    adjustedAmount
                  );
                }
              }
              while (Number(adjustedAmount) >= Number(totalSemiMonthlyRate)) {
                if (days_paid >= dueDate.getDate()) {
                  dueDate = new Date(
                    dueDate.getFullYear(),
                    dueDate.getMonth(),
                    daysRange[1],
                    dueDate.getHours(),
                    dueDate.getMinutes(),
                    dueDate.getSeconds(),
                    dueDate.getMilliseconds()
                  );
                  days_paid = daysRange[0];
                } else {
                  dueDate = new Date(
                    dueDate.getFullYear(),
                    dueDate.getMonth() + 1,
                    daysRange[0],
                    dueDate.getHours(),
                    dueDate.getMinutes(),
                    dueDate.getSeconds(),
                    dueDate.getMilliseconds()
                  );
                  days_paid = daysRange[1];
                }
                adjustedAmount = (
                  Number(adjustedAmount) - Number(totalSemiMonthlyRate)
                ).toFixed(2);
                console.log(adjustedAmount, "testing inside semimonthly");
                adjustDueDateResp = await ReverseCalculationResponse(
                  adjustDueDateResp,
                  agreementRates.semiMonthlyRate,
                  dueDate.toISOString()
                );
              }
              console.log(
                adjustDueDateResp,
                totalSemiMonthlyRate,
                adjustedAmount,
                "testing adjustDueDateResp"
              );
            }
          }
        } else {
          if (Number(adjustedAmount) >= Number(totalMonthlyRate)) {
            while (Number(adjustedAmount) >= Number(totalMonthlyRate)) {
              adjustedAmount = (
                Number(adjustedAmount) - Number(totalMonthlyRate)
              ).toFixed(2);
              const currentDateTemp = dueDate;
              dueDate = new Date(
                dueDate.getFullYear(),
                dueDate.getMonth() + 1,
                dueDate.getDate()
              );
              if (
                dueDate.getMonth() - currentDateTemp.getMonth() != 1 &&
                dueDate.getMonth() - currentDateTemp.getMonth() > 0
              ) {
                dueDate = new Date(
                  currentDateTemp.getFullYear(),
                  currentDateTemp.getMonth() + 2,
                  0
                );
              }
              adjustDueDateResp = await ReverseCalculationResponse(
                adjustDueDateResp,
                agreementRates.monthlyRate,
                dueDate.toISOString()
              );
            }
          }
        }
        if (Number(adjustedAmount) >= Number(totalBiWeeklyRate)) {
          while (Number(adjustedAmount) >= Number(totalBiWeeklyRate)) {
            adjustedAmount = (
              Number(adjustedAmount) - Number(totalBiWeeklyRate)
            ).toFixed(2);
            dueDate.setDate(dueDate.getDate() + 14);
            adjustDueDateResp = await ReverseCalculationResponse(
              adjustDueDateResp,
              agreementRates.biWeeklyRate,
              dueDate.toISOString()
            );
          }
        }
        if (Number(adjustedAmount) >= Number(totalWeeklyRate)) {
          while (Number(adjustedAmount) >= Number(totalWeeklyRate)) {
            adjustedAmount = (
              Number(adjustedAmount) - Number(totalWeeklyRate)
            ).toFixed(2);
            dueDate.setDate(dueDate.getDate() + 7);
            adjustDueDateResp = await ReverseCalculationResponse(
              adjustDueDateResp,
              agreementRates.weeklyRate,
              dueDate.toISOString()
            );
          }
        }
        if (Number(adjustedAmount) >= Number(totalPerDayRate)) {
          while (Number(adjustedAmount) >= Number(totalPerDayRate)) {
            adjustedAmount = (
              Number(adjustedAmount) - Number(totalPerDayRate)
            ).toFixed(2);
            dueDate.setDate(dueDate.getDate() + 1);
            adjustDueDateResp = await ReverseCalculationResponse(
              adjustDueDateResp,
              agreementRates.perDayRate,
              dueDate.toISOString()
            );
          }
        }
      }
      let totalAmount = 0;
      adjustDueDateResp.forEach((e: any, index: number) => {
        const rateResponseArray = adjustDueDateReq.rateResponse.filter(
          (e: any) => e.agreementId == adjustDueDateResp[index].agreementId
        );
        if (rateResponseArray.length > 0) {
          totalAmount =
            Number(adjustDueDateResp[index].agreementRate) +
            Number(adjustDueDateResp[index].rpayTaxAmount) +
            Number(adjustDueDateResp[index].ldwAmount) +
            Number(adjustDueDateResp[index].ldwTaxAmount) +
            Number(adjustDueDateResp[index].carryRent) +
            Number(adjustDueDateResp[index].carryRentTax);
          const epoAmount = rateResponseArray[0].epoAmount;
          console.log("testing totalAmount", totalAmount, epoAmount);
          if (
            Number(epoAmount) > 0 &&
            (Number(adjustDueDateReq.amountAdjusted) >= Number(epoAmount) ||
              totalAmount >= Number(epoAmount))
          ) {
            if (new Date(rateResponseArray[0].sacDate) >= presentDate) {
              adjustDueDateResp[index].nextDueDate = "SAC";
            } else {
              adjustDueDateResp[index].nextDueDate = "EPO";
            }
            adjustDueDateResp[index].agreementType = rateResponseArray[0]?.Agreementtype != 'Note Payment' ? "EPO Rent": "EPO Note";
            adjustDueDateResp[index].totalAmount = (
              Number(rateResponseArray[0].epoAmount) -
              Number(rateResponseArray[0].epoTax)
            ).toFixed(2);
            adjustDueDateResp[index].totalTax = rateResponseArray[0].epoTax;
            adjustDueDateResp[index].agreementRate = (
              Number(rateResponseArray[0].epoAmount) -
              Number(rateResponseArray[0].epoTax)
            ).toFixed(2);
            adjustDueDateResp[index].rpayTaxAmount =
              rateResponseArray[0].epoTax;
            adjustDueDateResp[index].ldwAmount =
              Number(rateResponseArray[0].isLdwAllowed) == 1
                ? adjustDueDateResp[index].ldwAmount
                : "0.00";
            adjustDueDateResp[index].ldwTaxAmount =
              Number(rateResponseArray[0].isLdwAllowed) == 1
                ? adjustDueDateResp[index].ldwTaxAmount
                : "0.00";
          }
        }
      });
      let forwardResponse: any;
      debugger;
      let duedate: any;
      for (let j = 0; j < adjustDueDateResp.length; j++) {
        if (adjustDueDateResp[j].agreementType != "EPO Rent" && adjustDueDateResp[j].agreementType != "EPO Note") {
          duedate = adjustDueDateResp[j].nextDueDate;
        }
      }
      if (duedate == undefined) {
        return adjustDueDateResp;
      } else if (forwardCalc == 1) {
        const forwardCalcReq: AdjustDueDate = {
          dueDate: duedate,
          rateResponse: adjustDueDateReq.rateResponse,
        };
        forwardCalc = 0;
        forwardResponse = await AdjustDueDatecalci(forwardCalcReq);
        console.log(forwardResponse);
      }
      debugger;
      let respArray: any = [];
      let recalculation: any;
      let recalculation2: any;
      let recalculationResp: any;
      for (let i = 0; i < forwardResponse.length; i++) {
        const total =
          Number(forwardResponse[i].totalAmount) +
          Number(forwardResponse[i].totalTax);
        const reverseTotal =
          Number(adjustDueDateResp[i].totalAmount) +
          Number(adjustDueDateResp[i].totalTax);
        if (total == reverseTotal) {
          respArray.push(forwardResponse[i]);
        } else if (
          total <= Number(adjustDueDateReq.amountAdjusted) &&
          reverseTotal <= Number(adjustDueDateReq.amountAdjusted)
        ) {
          respArray.push(forwardResponse[i]);
        } else if (total > Number(adjustDueDateReq.amountAdjusted)) {
          if (
            adjustDueDateResp[0].nextDueDate != "EPO" &&
            adjustDueDateResp[0].nextDueDate != "SAC"
          ) {
            const newDueDate = new Date(
              new Date(adjustDueDateResp[0].nextDueDate).toLocaleString(
                "en-US",
                { timeZone: "America/Chicago" }
              )
            );
            const dueDate = new Date(
              newDueDate.getFullYear(),
              newDueDate.getMonth(),
              newDueDate.getDate()
            );
            const newNextDueDate = moment(dueDate).format("MM/DD/YYYY");
            recalculation = {
              dueDate: newNextDueDate,
              rateResponse: adjustDueDateReq.rateResponse,
            };
          }
        }
      }

      if (recalculation != undefined && recalculation != null) {
        recalculationResp = await AdjustDueDatecalci(recalculation);
      }
      debugger;

      if (recalculationResp != undefined && recalculationResp != null) {
        for (let i = 0; i < recalculationResp.length; i++) {
          const total =
            Number(recalculationResp[i].totalAmount) +
            Number(recalculationResp[i].totalTax);
          const reverseTotal =
            Number(adjustDueDateResp[i].totalAmount) +
            Number(adjustDueDateResp[i].totalTax);
          if (total == reverseTotal) {
            respArray.push(recalculationResp[i]);
          } else if (
            total <= Number(adjustDueDateReq.amountAdjusted) &&
            reverseTotal <= Number(adjustDueDateReq.amountAdjusted)
          ) {
            respArray.push(recalculationResp[i]);
          } else if (total > Number(adjustDueDateReq.amountAdjusted)) {
            if (
              adjustDueDateResp[0].nextDueDate != "EPO" &&
              adjustDueDateResp[0].nextDueDate != "SAC"
            ) {
              const newDueDate = new Date(
                new Date(recalculationResp[i].nextDueDate).toLocaleString(
                  "en-US",
                  { timeZone: "America/Chicago" }
                )
              );
              const dueDate = new Date(
                newDueDate.getFullYear(),
                newDueDate.getMonth(),
                newDueDate.getDate()
              );
              const newNextDueDate = moment(dueDate).format("MM/DD/YYYY");
              recalculation2 = {
                dueDate: newNextDueDate,
                rateResponse: adjustDueDateReq.rateResponse,
              };
            }
          }
        }
      }
      debugger;
      if (recalculation2 != undefined && recalculation2 != null) {
        recalculationResp = await AdjustDueDatecalci(recalculation2);
        respArray = recalculationResp;
      }
      console.log(respArray, "testing response array");
      return respArray;
    }
  }
  return responseArray;
};

const AgreementScheduleRates = async (
  agreementScheduleRate: AgreementRateInfo,
  agreementRent: any,
  isLdwActive: number,
  isPolicyActive: number
) => {
  agreementScheduleRate.perDayRate.push({
    agreementRate: agreementRent.agreementRateDetails.perDayRent,
    agreementRateTax: agreementRent.agreementRateDetails.perDayRentTax,
    ldwAmount: isLdwActive == 1 ? agreementRent.agreementRateDetails.perDayLdw : "0.00",
    ldwAmountTax: isLdwActive == 1
      ? agreementRent.agreementRateDetails.perDayLdwTax
      : "0.00",
    policyAmount: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.perDayPolicy
      : "0.00",
    policyAmountTax: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.perDayPolicyTax
      : "0.00",
    agreementId: agreementRent.agreementId,
    lateFee: agreementRent.lateFee,
    lateFeeTax: agreementRent.lateFeeTax,
    currentDueDate: agreementRent.currentDueDate,
    agreementNumber: agreementRent.agreementNumber,
    carryRent: agreementRent.carryRent,
    carryRentTax: agreementRent.carryRentTax,
    ldwPercentage: agreementRent.ldwPercentage
  });
  agreementScheduleRate.weeklyRate.push({
    agreementRate: agreementRent.agreementRateDetails.weeklyRent,
    agreementRateTax: agreementRent.agreementRateDetails.weeklyRentTax,
    ldwAmount: isLdwActive == 1 ? agreementRent.agreementRateDetails.weeklyLdw : "0.00",
    ldwAmountTax: isLdwActive == 1
      ? agreementRent.agreementRateDetails.weeklyLdwTax
      : "0.00",
    policyAmount: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.weeklyPolicy
      : "0.00",
    policyAmountTax: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.weeklyPolicyTax
      : "0.00",
    agreementId: agreementRent.agreementId,
    lateFee: agreementRent.lateFee,
    lateFeeTax: agreementRent.lateFeeTax,
    currentDueDate: agreementRent.currentDueDate,
    agreementNumber: agreementRent.agreementNumber,
    carryRent: agreementRent.carryRent,
    carryRentTax: agreementRent.carryRentTax,
    ldwPercentage: agreementRent.ldwPercentage
  });
  agreementScheduleRate.biWeeklyRate.push({
    agreementRate: agreementRent.agreementRateDetails.biWeeklyRent,
    agreementRateTax: agreementRent.agreementRateDetails.biWeeklyRentTax,
    ldwAmount: isLdwActive == 1
      ? agreementRent.agreementRateDetails.biWeeklyLdw
      : "0.00",
    ldwAmountTax: isLdwActive == 1
      ? agreementRent.agreementRateDetails.biWeeklyLdwTax
      : "0.00",
    policyAmount: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.biWeeklyPolicy
      : "0.00",
    policyAmountTax: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.biWeeklyPolicyTax
      : "0.00",
    agreementId: agreementRent.agreementId,
    lateFee: agreementRent.lateFee,
    lateFeeTax: agreementRent.lateFeeTax,
    currentDueDate: agreementRent.currentDueDate,
    agreementNumber: agreementRent.agreementNumber,
    carryRent: agreementRent.carryRent,
    carryRentTax: agreementRent.carryRentTax,
    ldwPercentage: agreementRent.ldwPercentage
  });
  agreementScheduleRate.semiMonthlyRate.push({
    agreementRate: agreementRent.agreementRateDetails.semiMonthlyRent,
    agreementRateTax: agreementRent.agreementRateDetails.semiMonthlyRentTax,
    ldwAmount: isLdwActive == 1
      ? agreementRent.agreementRateDetails.semiMonthlyLdw
      : "0.00",
    ldwAmountTax: isLdwActive == 1
      ? agreementRent.agreementRateDetails.semiMonthlyLdwTax
      : "0.00",
    policyAmount: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.semiMonthlyPolicy
      : "0.00",
    policyAmountTax: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.semiMonthlyPolicyTax
      : "0.00",
    agreementId: agreementRent.agreementId,
    lateFee: agreementRent.lateFee,
    lateFeeTax: agreementRent.lateFeeTax,
    currentDueDate: agreementRent.currentDueDate,
    agreementNumber: agreementRent.agreementNumber,
    carryRent: agreementRent.carryRent,
    carryRentTax: agreementRent.carryRentTax,
    ldwPercentage: agreementRent.ldwPercentage
  });
  agreementScheduleRate.monthlyRate.push({
    agreementRate: agreementRent.agreementRateDetails.monthlyRent,
    agreementRateTax: agreementRent.agreementRateDetails.monthlyRentTax,
    ldwAmount: isLdwActive == 1 ? agreementRent.agreementRateDetails.monthlyLdw : "0.00",
    ldwAmountTax: isLdwActive == 1
      ? agreementRent.agreementRateDetails.monthlyLdwTax
      : "0.00",
    policyAmount: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.monthlyPolicy
      : "0.00",
    policyAmountTax: isPolicyActive == 1
      ? agreementRent.agreementRateDetails.monthlyPolicyTax
      : "0.00",
    agreementId: agreementRent.agreementId,
    lateFee: agreementRent.lateFee,
    lateFeeTax: agreementRent.lateFeeTax,
    currentDueDate: agreementRent.currentDueDate,
    agreementNumber: agreementRent.agreementNumber,
    carryRent: agreementRent.carryRent,
    carryRentTax: agreementRent.carryRentTax,
    ldwPercentage: agreementRent.ldwPercentage
  });
};

const ReverseCalculationResponse = async (
  adjustDueDateResponse: AdjustDueResponse[],
  rateDetails: RateInfo[],
  nextDueDate: any
): Promise<AdjustDueResponse[]> => {
  let response: AdjustDueResponse[] = [];
  if (adjustDueDateResponse.length != 0) {
    for (let index = 0; index < rateDetails.length; index++) {
      adjustDueDateResponse[index].agreementRate = (
        Number(adjustDueDateResponse[index].agreementRate) +
        Number(rateDetails[index].agreementRate)
      ).toFixed(2);
      adjustDueDateResponse[index].rpayTaxAmount = (
        Number(adjustDueDateResponse[index].rpayTaxAmount) +
        Number(rateDetails[index].agreementRateTax)
      ).toFixed(2);
      adjustDueDateResponse[index].ldwAmount = (
        Number(adjustDueDateResponse[index].ldwAmount) +
        Number(rateDetails[index].ldwAmount)
      ).toFixed(2);
      adjustDueDateResponse[index].ldwTaxAmount = (
        Number(adjustDueDateResponse[index].ldwTaxAmount) +
        Number(rateDetails[index].ldwAmountTax)
      ).toFixed(2);
      adjustDueDateResponse[index].policyAmount = (
        Number(adjustDueDateResponse[index].policyAmount) +
        Number(rateDetails[index].policyAmount)
      ).toFixed(2);
      adjustDueDateResponse[index].policyTaxAmount = (
        Number(adjustDueDateResponse[index].policyTaxAmount) +
        Number(rateDetails[index].policyAmountTax)
      ).toFixed(2);
      adjustDueDateResponse[index].totalAmount = (
        Number(adjustDueDateResponse[index].totalAmount) +
        Number(rateDetails[index].agreementRate) +
        Number(rateDetails[index].ldwAmount) +
        Number(rateDetails[index].policyAmount)
      ).toFixed(2);
      adjustDueDateResponse[index].totalTax = (
        Number(adjustDueDateResponse[index].totalTax) +
        Number(rateDetails[index].agreementRateTax) +
        Number(rateDetails[index].ldwAmountTax) +
        Number(rateDetails[index].policyAmountTax)
      ).toFixed(2);
      adjustDueDateResponse[index].nextDueDate = await ConvertDate(nextDueDate);
      response.push(adjustDueDateResponse[index]);
    }
    response = adjustDueDateResponse;
  } else {
    for (let index = 0; index < rateDetails.length; index++) {
      const resp: AdjustDueResponse = {
        agreementType: "Rental Agreement (RTO)",
        agreementRate: "0.00",
        nextDueDate: "",
        currentDueDate: "",
        lateFee: "0.00",
        ldwAmount: "0.00",
        policyAmount: "0.00",
        agreementId: "0.00",
        customerClubId: "0.00",
        ldwTaxAmount: "0.00",
        rpayTaxAmount: "0.00",
        lateFeeTaxAmount: "0.00",
        policyTaxAmount: "0.00",
        totalAmount: "0.00",
        totalTax: "0.00",
        agreementNumber: "",
        carryRent: "",
        carryRentTax: "",
        carriedLate: "",
        carriedLateTax: "",
        actualEditedValue: null,
        ldwPercentage: ""
      };
      resp.agreementRate = Number(rateDetails[index].agreementRate).toFixed(2);
      resp.rpayTaxAmount = Number(rateDetails[index].agreementRateTax).toFixed(
        2
      );
      resp.ldwAmount = Number(rateDetails[index].ldwAmount).toFixed(2);
      resp.ldwTaxAmount = Number(rateDetails[index].ldwAmountTax).toFixed(2);
      resp.policyAmount = Number(rateDetails[index].policyAmount).toFixed(2);
      resp.policyTaxAmount = Number(rateDetails[index].policyAmountTax).toFixed(
        2
      );
      resp.agreementId = rateDetails[index].agreementId;
      const carryLdw =
        Number(resp.ldwAmount) > 0 &&
          rateDetails[index].carryRent != "undefined" &&
          rateDetails[index].ldwPercentage != "undefined" && rateDetails[index].ldwPercentage
          ? (
            Number(rateDetails[index].carryRent) *
            Number(rateDetails[index].ldwPercentage)
          ).toFixed(2)
          : "0.00";
      const carryLdwTax =
        Number(carryLdw) > 0 && rateDetails[index].ldwPercentage != "undefined"
          ? (
            Number(carryLdw) * Number(rateDetails[index].ldwPercentage)
          ).toFixed(2)
          : "0.00";

      resp.totalAmount = (
        Number(resp.agreementRate) +
        Number(resp.ldwAmount) +
        Number(resp.policyAmount) +
        Number(rateDetails[index].lateFee) +
        Number(
          rateDetails[index].carryRent != "undefined"
            ? rateDetails[index].carryRent
            : "0.00"
        ) +
        Number(carryLdw)
      ).toFixed(2);
      resp.totalTax = (
        Number(resp.rpayTaxAmount) +
        Number(resp.ldwTaxAmount) +
        Number(resp.policyTaxAmount) +
        Number(
          rateDetails[index].lateFeeTax != "undefined"
            ? rateDetails[index].lateFeeTax
            : "0.00"
        ) +
        Number(
          rateDetails[index].carryRentTax != "undefined"
            ? rateDetails[index].carryRentTax
            : "0.00"
        ) +
        Number(carryLdwTax)
      ).toFixed(2);
      resp.lateFee = rateDetails[index].lateFee;
      resp.lateFeeTaxAmount =
        rateDetails[index].lateFeeTax != "undefined"
          ? rateDetails[index].lateFeeTax
          : "0.00";
      resp.currentDueDate = await ConvertDate(
        new Date(rateDetails[index].currentDueDate)
      );
      resp.nextDueDate = await ConvertDate(nextDueDate);
      resp.agreementNumber = rateDetails[index].agreementNumber;
      resp.carryRent = rateDetails[index].carryRent;
      resp.carryRentTax = rateDetails[index].carryRentTax;
      response.push(resp);
    }
  }
  return response;
};

const RentCalculation = async (
  laterentreq: RentCalculationRequest
): Promise<any> => {
  debugger;
  let monthly_calc_response: any;
  let Biweekly_calc_response: any;
  let weekly_calc_response: any;
  let { rate, due_date, late_days, remainingDays } = laterentreq;
  console.log("testing laterentreq inside the RentCalculation function", {
    laterentreq: laterentreq,
  });
  const {
    past_due_date,
    schedule,
    weekly_rate,
    biweekly_rate,
    monthly_rate,
    semi_monthly_rate,
    semimonthcount,
    semimonthrange,
  } = laterentreq;
  const currentdate: Date = new Date(past_due_date); // new Date(new Date(past_due_date).toLocaleString('en-US', { timeZone: 'America/Chicago' })); // new Date(past_due_date) //
  const current_date = currentdate; // new Date(new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate()).toLocaleString('en-US', { timeZone: 'America/Chicago' }));
  let updatedCurrentdate = currentdate;
  console.log("testing updatedCurrentdate", {
    updatedCurrentdate: updatedCurrentdate,
  });

  let isSemiMonth = 0;
  if (schedule == "WK" || schedule == "BIWK" || schedule == "MON") {
    let months;
    months = (due_date.getFullYear() - current_date.getFullYear()) * 12;

    if (current_date.getDate() <= due_date.getDate()) {
      months -= current_date.getMonth();
      months += due_date.getMonth();
    } else {
      months -= current_date.getMonth();
      months += due_date.getMonth();
      months = months - 1;
    }
    console.log("testing months", { months: months });

    if (months >= 1) {
      monthly_calc_response = await MonthlyRentCalculationFunc(
        rate,
        monthly_rate,
        current_date,
        due_date,
        months
      );
      console.log(
        "testing monthly_calc_response inside the laterentcalculation",
        { monthly_calc_response: monthly_calc_response }
      );
      //get the rate from monthly fn and update to the rate variable
      rate = monthly_calc_response.rate;
      //set the updated due_date
      due_date = monthly_calc_response.due_date;
      //get the remaining days from monthly function set to remaining days
      late_days = monthly_calc_response.remaining_days;
      updatedCurrentdate = new Date(monthly_calc_response.current_date);
    }
  } else {
    isSemiMonth = 1;
    const range_temp: any =
      semimonthrange != null || semimonthrange != undefined
        ? semimonthrange
        : "1-15";
    const count = semimonthcount;
    if (count > 0) {
      const semimonthObj: any = await SemiMonthlyRentCalculationFunc(
        rate,
        semi_monthly_rate,
        due_date,
        semimonthcount,
        current_date,
        range_temp,
        remainingDays
      );
      due_date = semimonthObj.due_date;
      rate = semimonthObj.rate;
      late_days = semimonthObj.remaining_days;
      updatedCurrentdate = new Date(semimonthObj.current_date);
      console.log("testing in semimonthly", { semimonthObj: semimonthObj });
    }
  }
  if (late_days >= 14) {
    const Bi_weekly_count = Math.trunc(late_days / 14);
    Biweekly_calc_response = await BiWeeklyRentCalculationFunc(
      rate,
      biweekly_rate,
      updatedCurrentdate,
      due_date,
      Bi_weekly_count,
      isSemiMonth,
      remainingDays
    );
    console.log("testing in biweekly calculation", {
      Biweekly_calc_response: Biweekly_calc_response,
    });
    //update the rate from biweekly function
    rate = Biweekly_calc_response.rate;
    //get the remaining days from biweekly function set to remaining days
    late_days = Biweekly_calc_response.remaining_days;
    remainingDays = remainingDays > 0 ? remainingDays - 14 : 0;
    //get the due date from biweekly returned object and update
    due_date = Biweekly_calc_response.due_date;
    updatedCurrentdate = new Date(Biweekly_calc_response.current_date);
  }
  if (late_days >= 7) {
    //calculate the number of weeks
    const weekly_count = Math.trunc(late_days / 7);
    //call weekly function which calculate the rate based on weeks
    weekly_calc_response = await WeeklyRentCalculationFunc(
      rate,
      weekly_rate,
      updatedCurrentdate,
      due_date,
      weekly_count,
      isSemiMonth,
      remainingDays
    );
    console.log("testing rate in weekly calculation", {
      weekly_calc_response: weekly_calc_response,
    });
    //get the rate from weekly fn and update to the rate variable
    rate = weekly_calc_response.rate;
    //get the remaining days from weekly function set to remaining days
    late_days = weekly_calc_response.remaining_days;
    remainingDays = remainingDays > 0 ? remainingDays - 7 : 0;
    //get the updated due date
    due_date = weekly_calc_response.due_date;
    updatedCurrentdate = new Date(weekly_calc_response.current_date);
  }
  if (late_days > 0) {
    const per_day_rate = parseFloat(weekly_rate) / 7;
    //add the rate with the rate for remaining days
    rate = (parseFloat(rate) + per_day_rate * late_days).toFixed(2);
    console.log("testing in rate in one day calculation", { rate: rate });
  } else {
    rate = parseFloat(rate).toFixed(2);
  }
  console.log("current dateafter rate calculation", currentdate);
  return rate;
};

const MonthlyRentCalculationFunc = async (
  rate: string,
  monthly_rate: string,
  current_date: Date,
  due_date: Date,
  months: number
): Promise<CalculationFunction> => {
  const rate_local = (
    parseFloat(rate) +
    parseFloat(monthly_rate) * months
  ).toString();
  for (let index = 0; index < months; index++) {
    const currentDateTemp = new Date(current_date);
    const date = current_date.getDate();
    const daysInFeb = new Date(currentDateTemp.getFullYear(), 2, 0).getDate();
    if (current_date.getMonth() == 0 && date > daysInFeb) {
      current_date = new Date(currentDateTemp.getFullYear(), 1, daysInFeb);
    } else {
      current_date = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        current_date.getDate()
      );
    }
  }
  console.log("testing current previouscurrent and due date", {
    current_date,
    due_date,
  });
  //calculate the no: of days btween current_date and due_date after removing the calculated months
  const remaining_days = Number(
    (
      (due_date.getTime() - current_date.getTime()) /
      (1000 * 3600 * 24)
    ).toFixed()
  );
  console.log("due date and remaining days in funcMonthly", {
    due_date,
    remaining_days: remaining_days,
  });
  console.log("testing monthly remaining_days", {
    remaining_days: remaining_days,
  });
  const monthlyObj: CalculationFunction = {
    rate: rate_local,
    remaining_days: remaining_days,
    due_date: due_date,
    current_date: current_date,
  };
  return monthlyObj;
};

const BiWeeklyRentCalculationFunc = async (
  rate: string,
  biweekly_rate: string,
  current_date: Date,
  due_date: Date,
  Bi_weekly_count: number,
  isSemiMonth: number,
  remDays: number
): Promise<CalculationFunction> => {
  const rate_local = (
    parseFloat(rate) +
    parseFloat(biweekly_rate) * Bi_weekly_count
  ).toString();
  const dayscount = Bi_weekly_count * 14;
  current_date.setDate(current_date.getDate() + dayscount);
  //calculate remaining days
  const remaining_days: number =
    isSemiMonth == 1
      ? remDays - dayscount
      : Number(
        (
          (due_date.getTime() - current_date.getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      );
  console.log("testing bi-weekly remaining_days", {
    remaining_days: remaining_days,
  });
  console.log("due date and remaining days in funcBiweekly", {
    due_date: due_date,
    remaining_days: remaining_days,
  });
  const return_obj: CalculationFunction = {
    rate: rate_local,
    remaining_days: remaining_days,
    due_date: due_date,
    current_date: current_date,
  };
  return return_obj;
};

const WeeklyRentCalculationFunc = async (
  rate: string,
  weekly_rate: string,
  current_date: Date,
  due_date: Date,
  weekly_count: number,
  isSemiMonth: number,
  remDays: number
): Promise<CalculationFunction> => {
  const rate_local = (
    parseFloat(rate) +
    parseFloat(weekly_rate) * weekly_count
  ).toString();
  const dayscount: number = weekly_count * 7;
  current_date.setDate(current_date.getDate() + dayscount);
  //calculate remaining days
  const remaining_days: number =
    isSemiMonth == 1
      ? remDays - dayscount
      : Number(
        (
          (due_date.getTime() - current_date.getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      );
  console.log("remaining days and due date after calculationg weekly func", {
    remaining_days: remaining_days,
    due_date: due_date,
  });
  console.log("testing weekly remaining_days", {
    remaining_days: remaining_days,
  });
  const return_obj: CalculationFunction = {
    rate: rate_local,
    due_date: due_date,
    remaining_days: remaining_days,
    current_date: current_date,
  };
  return return_obj;
};

const SemiMonthlyRentCalculationFunc = async (
  rate: string,
  semimonthly_rate: string,
  due_date: Date,
  count: number,
  past_due_date: Date,
  range: any,
  remDays: number
): Promise<CalculationFunction> => {
  const rate_local = (
    parseFloat(rate) +
    parseFloat(semimonthly_rate) * count
  ).toString();
  const daysRange: any[] = range != null ? range.split("-") : "";
  const daysRange1 = daysRange[0];
  const daysRange2 = daysRange[1];
  let current_date = past_due_date;
  const newDaysRange = new Date();
  let remaining_days = 0;
  // if (isSemiMonth == 1) {
  console.log("testing remaining days in past due till date calculation", {
    remDays: remDays,
  });
  remaining_days = remDays;
  // remaining_days = (due_date.getDate() == daysRange1 || due_date.getDate() == daysRange2) ? 0 : remDays;
  // }
  //  else {
  //     if (past_due_date.getDate() != daysRange1 && past_due_date.getDate() != daysRange2) {
  //         if (past_due_date.getDate() < daysRange[0]) {
  //             newDaysRange = new Date(past_due_date.getFullYear(), past_due_date.getMonth(), daysRange[0]);
  //             remaining_days = ((newDaysRange.getTime() - past_due_date.getTime()) / (1000 * 3600 * 24));
  //         }
  //         else if (past_due_date.getDate() > daysRange[0] && past_due_date.getDate() < daysRange[1]) {
  //             newDaysRange = new Date(past_due_date.getFullYear(), past_due_date.getMonth(), daysRange[1]);
  //             remaining_days = ((newDaysRange.getTime() - past_due_date.getTime()) / (1000 * 3600 * 24));
  //         }
  //         else if (past_due_date.getDate() > daysRange[1]) {
  //             newDaysRange = new Date(past_due_date.getFullYear(), past_due_date.getMonth() + 1, daysRange[0]);
  //             remaining_days = ((newDaysRange.getTime() - past_due_date.getTime()) / (1000 * 3600 * 24));
  //         }
  //     }
  // }
  due_date = new Date(due_date);
  current_date = new Date(
    current_date.getFullYear(),
    current_date.getMonth(),
    current_date.getDate()
  );
  console.log("testing semimonthly remaining_days", {
    remaining_days: remaining_days,
  });
  const return_obj: CalculationFunction = {
    rate: rate_local,
    remaining_days: remaining_days,
    due_date: newDaysRange,
    current_date: current_date,
  };
  return return_obj;
};

const countOfSemimonths = async (
  current_due_date: Date,
  due_date: Date,
  range: string,
  isEditSchedule: number
): Promise<SemiMonthCount> => {
  debugger;
  const daysRange: any = range != null ? range.split("-") : "";
  let semimonthcount = 0;
  const current_date: Date = current_due_date;
  const daysRange1 = daysRange[0];
  const daysRange2 = daysRange[1];
  let days_paid: any = daysRange1;
  let newDaysRange = new Date(due_date);
  let remaining_days = 0;
  let oldDueDate = new Date(due_date);

  console.log(current_date, due_date);
  if (due_date.getDate() != daysRange1 && due_date.getDate() != daysRange2) {
    if (due_date.getDate() < daysRange[0]) {
      newDaysRange = new Date(
        due_date.getFullYear(),
        due_date.getMonth(),
        daysRange[0]
      );
      remaining_days =
        current_date >= newDaysRange
          ? Number(
            (
              (newDaysRange.getTime() - due_date.getTime()) /
              (1000 * 3600 * 24)
            ).toFixed()
          )
          : 0;
    } else if (
      due_date.getDate() > daysRange[0] &&
      due_date.getDate() < daysRange[1]
    ) {
      newDaysRange = new Date(
        due_date.getFullYear(),
        due_date.getMonth(),
        daysRange[1]
      );
      remaining_days =
        current_date >= newDaysRange
          ? Number(
            (
              (newDaysRange.getTime() - due_date.getTime()) /
              (1000 * 3600 * 24)
            ).toFixed()
          )
          : 0;
    } else if (due_date.getDate() > daysRange[1]) {
      newDaysRange = new Date(
        due_date.getFullYear(),
        due_date.getMonth() + 1,
        daysRange[0]
      );
      remaining_days =
        current_date >= newDaysRange
          ? Number(
            (
              (newDaysRange.getTime() - due_date.getTime()) /
              (1000 * 3600 * 24)
            ).toFixed()
          )
          : 0;
    }
  }
  console.log("testing remaining days", {
    remaining_days: remaining_days,
    newDaysRange: newDaysRange,
  });

  // if (due_date.getDate() != daysRange1 || due_date.getDate() != daysRange2) {
  //     if ((due_date.getDate()) > daysRange1 && (due_date.getDate()) < daysRange2) {
  //         due_date.setDate(daysRange2);
  //     }
  //     else if ((due_date.getDate()) > daysRange2) {
  //         due_date = new Date(due_date.getFullYear(), due_date.getMonth() + 1, daysRange1, due_date.getHours(), due_date.getMinutes(), due_date.getSeconds(), due_date.getMilliseconds());
  //     }
  //     else if ((due_date.getDate()) < daysRange1) {
  //         due_date.setDate(daysRange1);
  //     }
  // }
  // console.log('testing newDueDate inside if condition', { new_due_date: due_date });

  while (current_date >= newDaysRange) {
    oldDueDate = new Date(newDaysRange);
    if (days_paid >= newDaysRange.getDate()) {
      newDaysRange = new Date(
        newDaysRange.getFullYear(),
        newDaysRange.getMonth(),
        daysRange[1],
        newDaysRange.getHours(),
        newDaysRange.getMinutes(),
        newDaysRange.getSeconds(),
        newDaysRange.getMilliseconds()
      );
      days_paid = daysRange1;
      semimonthcount += 1;
    } else {
      newDaysRange = new Date(
        newDaysRange.getFullYear(),
        newDaysRange.getMonth() + 1,
        daysRange[0],
        newDaysRange.getHours(),
        newDaysRange.getMinutes(),
        newDaysRange.getSeconds(),
        newDaysRange.getMilliseconds()
      );
      days_paid = daysRange2;
      semimonthcount += 1;
    }
    semimonthcount =
      newDaysRange > current_date ? semimonthcount - 1 : semimonthcount;
    // console.log('testing dueDate', { semimonthcount: semimonthcount, due_date: due_date });
  }

  remaining_days =
    isEditSchedule == 1
      ? remaining_days
      : remaining_days +
      Number(
        (
          (current_date.getTime() - oldDueDate.getTime()) /
          (1000 * 3600 * 24)
        ).toFixed()
      );
  console.log("testing semimonthcount", {
    semimonthcount: semimonthcount,
    current_date: current_date,
    due_date: due_date,
    remaining_days: remaining_days,
  });
  const response: SemiMonthCount = {
    semiMonthCount: semimonthcount,
    remainingDays: remaining_days,
  };
  console.log(response);
  return response;
};

const ConvertDate = async (inputFormat: Date): Promise<string> => {
  function pad(s: any) {
    return s < 10 ? "0" + s : s;
  }
  const d = new Date(inputFormat);
  return [pad(d.getMonth() + 1), pad(d.getDate()), d.getFullYear()].join("/");
};

export default AdjustDueDatecalci;

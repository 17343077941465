/* eslint-disable prettier/prettier */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import React, { createContext, useState, useEffect } from "react";
export const GeneralStateContext = createContext<any>("");
export const GeneralStateDispatchContext = createContext<any>("");
import {
  ReverseReasonObject,
  receiptDetailsExceptionsObject,
  PostReversalObject,
  exceptionModalObject,
  loadingModalObject,
  tenderTypeUsedObject,
  CCAmountObject,
} from "../constants/constants";
import { useParams } from "react-router-dom";
import { ParamType } from "../constants/constants";
export default function GeneralProvider(props: any) {
  const { customerId } = useParams<ParamType>(); // Holds the customer ID
  const [reverseReason, setReverseReason] = useState(false); // For Reason Popup
  const [openSLA, setOpenSLA] = useState(false); // For SLA Popup
  const [CCAmount, setCCAmount] = useState(CCAmountObject);
  const [cardReversalDetails, setCardReversalDetails] = useState([]);
  const [isPostReversalFailed, setIsPostReversalFailed] = useState(false);
  const [disableReversePayment, setDisableReversePayment] = useState(false);
  const [openTransferException, setOpenTransferException] = useState(false);
  const [failedReceiptFetching, setFailedReceiptFetching] = useState(false);
  const [reverseReceiptID, setReversedReceiptID] = useState("");
  const [loadingModal, setLoadingModal] = useState(loadingModalObject); // For SLA Popup
  const [receiptDetailsException, setReceiptDetailsException] = useState(
    receiptDetailsExceptionsObject
  ); // Holds Exception Type
  const [reverseConfirmation, setReverseConfirmation] = useState(false); // For Reverse Confirmation Popup
  const [confirmationMessage, setConfirmationMessage] = useState(""); // Confirmation Message Holder
  const [messageTracker, setMessageTracker] = useState(0); // Control Message Order
  const [cardConfirmationMessage, setCardConfirmationMessage] = useState(""); // Final Amount Holder Text
  const [controlReverseButton, setControlReverseButton] = useState(true); // Control Reverse Button State
  const [reversePayData, setReversePayData]: any = useState(); // Holds Entire Response
  const [customerInformation, setCustomerInformation] = useState<any>(); // Holds Customer Information
  const [reverseReasonValue, setReverseReasonValue] =
    useState(ReverseReasonObject); // Holds Reverse Reason Values
  const [accordianIndex, setAccordianIndex] = useState<any>([]); // Holds Accordian Index
  const [reversePayOptions, setReversePayOptions] = useState<any>([]); // Holds All Reverse Reason Values
  const [selectedMasterCheckBox, setSelectedMasterCheckBox] = useState<any>([]); // Holds Master Check Box State
  const [individualRowTotal, setIndividualRowTotal] = useState<any>([]); // Holds Individual Row Amount
  const [selectedChildCheckBox, setSelectedChildCheckBox] = useState<any>([]); // Holds Child Check Box State
  const [openReceipt, setOpenReceipt] = useState(false); // Opens Receipt Popup
  const [cardReversal, setCardReversal] = useState(false); // open Card Reversal Popup
  const [openReverseReceipt, setOpenReverseReceipt] = useState(false);
  const [openViewReceipt, setOpenViewReceipt] = useState(false);
  const [reverseReceiptURL, setReverseReceiptURL] = useState("");
  const [pendingReversal, setPendingReversal] = useState(false); // open Pending Card Reversal Popup
  const [cardTransactionFailed, setCardTransactionFailed] = useState(false); // open Pending Card Reversal Popup
  const [blobURL, setBlobURL] = useState<any>([]);
  const [exceptionModal, setExceptionModal] = useState(exceptionModalObject);
  const [selectedAgreements, setSelectedAgreements] = useState([]);
  const [selectedInventories, setSelectedInventories] = useState([]);
  const [tenderTypesUsed, setTenderTypeUsed] = useState(tenderTypeUsedObject);
  const [openCheckMOPopup, setOpenCheckMOPopup] = useState(false);
  const [isClubReversible, setIsClubReversible] = useState("N");
  const [isSIMSReceipt, setIsSIMSReceipt] = useState(false);
  const [disableSaveBtn, setDisableSaveBtn] = useState(0);
  const [restrictWFG, setRestrictWFG] = useState(false);
  const [CurrentRole, setCurrentRole] = useState("");
  const [otherCOAAmount, setOtherCOAAmount] = useState(0);
  const [otherSusPayAmt, setOtherSusPayAmt] = useState(0);
  const [gridCardDetails, setGridCardDetails] = useState([]);
  const [disableContinueButton, setDisableContinueButton] = useState(true);
  const [failedCCAttempt, setFailedCCAttempt] = useState([]);
  const [continueController, setContinueController] = useState([]);
  const [transferCOAModel, setTransferCOAModel] = useState(false);
  const [otherAmountToBeAdded, setOtherAmountToBeAdded] = useState(0);
  const [otherAmountToBeSub, setOtherAmountToBeSub] = useState(0);
  const [choosenLineItems, setChoosenLineItems] = useState<any>([]);
  const [amountToDisplay, setAmountToDisplay] = useState([]);
  const [employeeId, setEmployeeId] = useState<any>("");
  const [postReversePayPayload, setPostReversePayPayload] =
    useState(PostReversalObject); // Holds Reverse Pay Payload
  const [selectedTransactionType, setSelectedTransactionType] = useState("2");
  const [actionTBTOpen, setActionTBTOpen] = useState(false);
  const [choosenSwipeData, setChoosenSwipeData] = useState();
  const [openTerminalPopup, setOpenTerminalPopup] = useState(false);
  const [selectedTerminal, setSelectedTerminal] = useState("");
  const [isVoidFlow, setIsVoidFlow] = useState(false);
  const [openVoidConfirmation, setOpenVoidConfirmation] = useState(false);
  const [openVoidRemainder, setOpenVoidRemainder] = useState(false);
  const [availableSwipeDevices, setAvailableSwipeDevices] = useState();
  const [disableMasterCheck, setDisableMasterCheck] = useState(true);
  const [donationAmountToRefund, setDonationAmountToRefund] = useState(0);
  const [isCloudReverseSwipePayment, setIsCloudReverseSwipePayment] = useState(0);
  const [registerNumber, setRegisterNumber] = useState();
  const [isCloudStoreSwipeDeviceList, setIsCloudStoreSwipeDeviceList] =
    useState<any>(0);
  const [isSwipeCustomerCard, setIsSwipeCustomerCard] = useState(0)
  const [isCloudReverseCCPayment, setIsCloudReverseCCPayment] =
    useState<any>(0);
  const [isEnableCashAppFeature, setIsEnableCashAppFeature] = useState(0);
  const [isCashAppMethodPresent, setIsCashAppMethodPresent] = useState<boolean>(false)
  useEffect(() => {
    if (choosenLineItems.length) {
      const isOnlyClubChoosen = choosenLineItems.every((el) => {
        return el.paymentType == "Club";
      });
      const isOnlyRegularChoosen = choosenLineItems.every((el) => {
        return el.initialPaymentFlag != "1" && el.initialPaymentFlag;
      });
      const hasClubData = choosenLineItems.filter((el) => {
        return el.paymentType == "Club";
      });
      const hasRegularData = choosenLineItems.filter((el) => {
        return el.initialPaymentFlag != "1" && el.initialPaymentFlag;
      });
      setRestrictWFG(
        isOnlyRegularChoosen ||
        isOnlyClubChoosen ||
        (hasClubData.length > 0 && hasRegularData.length > 0)
      );
      setControlReverseButton(false);
    } else {
      setRestrictWFG(false);
      setControlReverseButton(true);
    } // Drop Down Handle

    let calculatedTotal = 0;
    individualRowTotal.map((value) => {
      calculatedTotal = calculatedTotal + Number(value);
    }); // Raw Row Total

    if (
      selectedMasterCheckBox.length &&
      reversePayData[0].paymentReceiptDetails.every(
        (el) => el.isReversed === false
      ) &&
      reversePayData[0].paymentReceiptDetails.every(
        (el) => el.isReversible === true
      )
    ) {
      calculatedTotal =
        Number(calculatedTotal) +
        Number(otherAmountToBeAdded) -
        (Number(otherAmountToBeSub) + Number(otherSusPayAmt));
    } else {
      let calculatedSusPay = 0;
      if (reversePayData) {
        reversePayData[0].otherPaymentAmount.map((otherObj) => {
          choosenLineItems.map((agrObj) => {
            if (
              otherObj.accountType == "SUSPAY" &&
              otherObj.agreementId &&
              agrObj.agreementId &&
              otherObj.agreementId == agrObj.agreementId
            ) {
              calculatedSusPay =
                Number(calculatedSusPay) + Number(otherObj.otherAmount);
            }
          });
        });
        const unChoosenAgreementFilter: any = [];
        reversePayData[0].paymentReceiptDetails.map((agrObj) => {
          choosenLineItems.map((choosenAgrObj) => {
            if (agrObj.agreementId !== choosenAgrObj.agreementId) {
              unChoosenAgreementFilter.push(agrObj);
            }
          });
        });
        if (unChoosenAgreementFilter.length) {
          unChoosenAgreementFilter.map((agrObj) => {
            reversePayData[0].otherPaymentAmount.map((otherObj) => {
              if (
                otherObj.accountType == "SUSPAY" &&
                agrObj.agreementId == otherObj.agreementId &&
                Number(otherObj.otherAmount) > Number(agrObj.amountToReturn)
              ) {
                calculatedSusPay =
                  Number(calculatedSusPay) +
                  (Number(otherObj.otherAmount) -
                    Number(agrObj.amountToReturn));
              }
            });
          });
        }
        calculatedTotal =
          Number(calculatedTotal) -
          (Number(otherAmountToBeSub) + Number(calculatedSusPay));
      }
    } // Other Pay Amount


    setCardConfirmationMessage(
      `Debit card, Check, MO & Cash amounts will be refunded as cash. Verify you have $${Number(calculatedTotal) >= 0 ? calculatedTotal.toFixed(2) : "0.00"
      } cash funds available to do this cash refund. Do you want to continue with the payment reversal ?`
    );
    if (
      tenderTypesUsed.card &&
      !tenderTypesUsed.cash &&
      !tenderTypesUsed.check
    ) {
      let CEPSum = 0;
      reversePayData[0].tenderedTypesUsed.cardDetails.map((obj: any) => {
        CEPSum =
          CEPSum + (Number(obj.cardAmount) - Number(obj.cardAmountReversed));
      });


      setCCAmount({
        ...CCAmount,
        totalCardPayEligible: (Number(CEPSum) - Number(calculatedTotal) > 0
          ? Number(calculatedTotal)
          : Number(CEPSum)
        ).toFixed(2),
        lastPaymentReceived: individualRowTotal.reduce(
          (a, b) => Number(a) + Number(b),
          0
        ),
      });

      if (selectedMasterCheckBox.length) {
        const cardFilter: any = [];
        let amountToReverse: any = (
          Number(CEPSum) - Number(calculatedTotal) > 0
            ? Number(calculatedTotal)
            : Number(CEPSum)
        ).toFixed(2);
        console.log('testing inside master check box', amountToReverse);
        reversePayData[0].tenderedTypesUsed.cardDetails.map((obj) => {
          console.log("amountToReverse", amountToReverse);
          if (Number(amountToReverse) > 0) {
            amountToReverse =
              Number(amountToReverse) -
              (Number(obj.cardAmount) - Number(obj.cardAmountReversed));
            cardFilter.push(obj);
          }
        });
        
        
        if (cardFilter.length === 0)
          setGridCardDetails(cardFilter);
        else
          setGridCardDetails(reversePayData[0].tenderedTypesUsed.cardDetails);
        console.log(cardFilter, gridCardDetails, 'testing cardDetails');
      } else {
        const cardFilter: any = [];
        let amountToReverse: any = (
          Number(CEPSum) - Number(calculatedTotal) > 0
            ? Number(calculatedTotal)
            : Number(CEPSum)
        ).toFixed(2);
        reversePayData[0].tenderedTypesUsed.cardDetails.map((obj) => {
          console.log("amountToReverse", amountToReverse);
          if (Number(amountToReverse) > 0) {
            amountToReverse =
              Number(amountToReverse) -
              (Number(obj.cardAmount) - Number(obj.cardAmountReversed));
            cardFilter.push(obj);
          }
        });
        setGridCardDetails(cardFilter);
      } // CC List
    } else if (
      tenderTypesUsed.card &&
      (tenderTypesUsed.cash || tenderTypesUsed.check)
    ) {
      let CEPSum = 0;
      reversePayData[0].tenderedTypesUsed.cardDetails.map((obj: any) => {
        CEPSum =
          CEPSum + (Number(obj.cardAmount) - Number(obj.cardAmountReversed));
      });
      setCCAmount({
        ...CCAmount,
        lastPaymentReceived: individualRowTotal.reduce(
          (a, b) => Number(a) + Number(b),
          0
        ),
        totalCardPayEligible: (Number(CEPSum) - Number(calculatedTotal) > 0
          ? Number(calculatedTotal)
          : Number(CEPSum)
        ).toFixed(2),
        totalCashPayEligible:
          Number(CEPSum) - Number(calculatedTotal) > 0
            ? "0.00"
            : (Number(calculatedTotal) - Number(CEPSum)).toFixed(2),
      });
      setCardConfirmationMessage(
        `Debit card, Check, MO & Cash amounts will be refunded as cash. Verify you have $${Number(CEPSum) - Number(calculatedTotal) > 0
          ? "0.00"
          : (Number(calculatedTotal) - Number(CEPSum)).toFixed(2)
        } cash funds available to do this cash refund. Do you want to continue with the payment reversal ?`
      );
      if (selectedMasterCheckBox.length) {
        const cardFilter: any = [];
        let amountToReverse: any = (
          Number(CEPSum) - Number(calculatedTotal) > 0
            ? Number(calculatedTotal)
            : Number(CEPSum)
        ).toFixed(2);
        console.log('testing inside master check box', amountToReverse);
        reversePayData[0].tenderedTypesUsed.cardDetails.map((obj) => {
          console.log("amountToReverse", amountToReverse);
          if (Number(amountToReverse) > 0) {
            amountToReverse =
              Number(amountToReverse) -
              (Number(obj.cardAmount) - Number(obj.cardAmountReversed));
            cardFilter.push(obj);
          }
        });

        
        if (cardFilter.length === 0)
          setGridCardDetails(cardFilter);
        else
          setGridCardDetails(reversePayData[0].tenderedTypesUsed.cardDetails);
        console.log(cardFilter, gridCardDetails, 'testing cardDetails');
      } else {
        const cardFilter: any = [];
        let amountToReverse: any = (
          Number(CEPSum) - Number(calculatedTotal) > 0
            ? Number(calculatedTotal)
            : Number(CEPSum)
        ).toFixed(2);
        reversePayData[0].tenderedTypesUsed.cardDetails.map((obj) => {
          console.log("amountToReverse", amountToReverse);
          if (Number(amountToReverse) > 0) {
            amountToReverse =
              Number(amountToReverse) -
              (Number(obj.cardAmount) - Number(obj.cardAmountReversed));
            cardFilter.push(obj);
          }
        });
        setGridCardDetails(cardFilter);
      } // CC List
    }

    // let calculatedReverseAmount = 0;
    // individualRowTotal.map((value) => {
    //   calculatedReverseAmount = calculatedReverseAmount + Number(value);
    // });

    setPostReversePayPayload({
      ...postReversePayPayload,
      storeNumber: String(window.sessionStorage.getItem("storeNumber")),
      customerId: customerId,
      reasonType: reverseReasonValue.reverseReason,
      reasonText: reverseReasonValue.reasonInformation,
      receiptInfo: [
        {
          receiptId:
            reversePayData !== undefined ? reversePayData[0].receiptId : "",
          agreementId: selectedAgreements.length ? selectedAgreements : null,
          inventoryId: selectedInventories.length ? selectedInventories : undefined,
          isClubReversible: isClubReversible,
          reversedAmount: Number(calculatedTotal) > 0 ? calculatedTotal.toFixed(2) : '0.00',
        },
      ],
    });
  }, [individualRowTotal, reverseReasonValue]); // PC-04 This method is responsible for all the calculation based on the choosen agreements

  useEffect(() => {
    const allowContinue = continueController.every((el) => el === 1);
    setDisableContinueButton(!allowContinue);
  }, [continueController]);

  useEffect(() => {
    console.log("postReversePayPayload cdsxcvfdsxcvfe", postReversePayPayload);
    console.log("reverseReceiptURL value in context", reverseReceiptURL);
  }, [postReversePayPayload, reversePayData, reverseReceiptURL]);

  return (
    <GeneralStateContext.Provider
      value={{
        reverseReason,
        openSLA,
        reverseConfirmation,
        confirmationMessage,
        messageTracker,
        reverseReasonValue,
        accordianIndex,
        reversePayData,
        customerInformation,
        selectedMasterCheckBox,
        individualRowTotal,
        selectedChildCheckBox,
        controlReverseButton,
        cardConfirmationMessage,
        reversePayOptions,
        openReceipt,
        blobURL,
        receiptDetailsException,
        cardReversal,
        selectedAgreements,
        exceptionModal,
        postReversePayPayload,
        loadingModal,
        openCheckMOPopup,
        tenderTypesUsed,
        pendingReversal,
        cardTransactionFailed,
        isClubReversible,
        openReverseReceipt,
        reverseReceiptURL,
        openViewReceipt,
        restrictWFG,
        disableSaveBtn,
        isSIMSReceipt,
        choosenLineItems,
        CurrentRole,
        CCAmount,
        cardReversalDetails,
        otherAmountToBeAdded,
        otherAmountToBeSub,
        otherSusPayAmt,
        otherCOAAmount,
        transferCOAModel,
        failedCCAttempt,
        continueController,
        gridCardDetails,
        disableContinueButton,
        employeeId,
        amountToDisplay,
        isPostReversalFailed,
        disableReversePayment,
        openTransferException,
        selectedTransactionType,
        actionTBTOpen,
        choosenSwipeData,
        openTerminalPopup,
        selectedTerminal,
        availableSwipeDevices,
        isVoidFlow,
        openVoidConfirmation,
        openVoidRemainder,
        disableMasterCheck,
        isCloudReverseCCPayment,
        isCloudStoreSwipeDeviceList,
        donationAmountToRefund,
        isCloudReverseSwipePayment,
        registerNumber,
        selectedInventories,
        reverseReceiptID,
        failedReceiptFetching,
        isSwipeCustomerCard,
        isEnableCashAppFeature,
        isCashAppMethodPresent
      }}
    >
      <GeneralStateDispatchContext.Provider
        value={{
          setIsCashAppMethodPresent,
          setIsEnableCashAppFeature,
          setIsSwipeCustomerCard,
          setReverseReason,
          setOpenSLA,
          setReverseConfirmation,
          setConfirmationMessage,
          setMessageTracker,
          setReverseReasonValue,
          setAccordianIndex,
          setReversePayData,
          setCustomerInformation,
          setSelectedMasterCheckBox,
          setIndividualRowTotal,
          setSelectedChildCheckBox,
          setControlReverseButton,
          setReversePayOptions,
          setOpenReceipt,
          setBlobURL,
          setReceiptDetailsException,
          setCardReversal,
          setSelectedAgreements,
          setExceptionModal,
          setLoadingModal,
          setOpenCheckMOPopup,
          setTenderTypeUsed,
          setPendingReversal,
          setCardTransactionFailed,
          setIsClubReversible,
          setOpenReverseReceipt,
          setReverseReceiptURL,
          setOpenViewReceipt,
          setDisableSaveBtn,
          setChoosenLineItems,
          setRestrictWFG,
          setIsSIMSReceipt,
          setCurrentRole,
          setCCAmount,
          setCardReversalDetails,
          setOtherAmountToBeAdded,
          setOtherAmountToBeSub,
          setOtherSusPayAmt,
          setOtherCOAAmount,
          setTransferCOAModel,
          setFailedCCAttempt,
          setContinueController,
          setGridCardDetails,
          setDisableContinueButton,
          setEmployeeId,
          setAmountToDisplay,
          setIsPostReversalFailed,
          setDisableReversePayment,
          setOpenTransferException,
          setSelectedTransactionType,
          setActionTBTOpen,
          setChoosenSwipeData,
          setOpenTerminalPopup,
          setSelectedTerminal,
          setAvailableSwipeDevices,
          setIsVoidFlow,
          setOpenVoidConfirmation,
          setOpenVoidRemainder,
          setDisableMasterCheck,
          setIsCloudReverseCCPayment,
          setIsCloudStoreSwipeDeviceList,
          setDonationAmountToRefund,
          setIsCloudReverseSwipePayment,
          setRegisterNumber,
          setSelectedInventories,
          setFailedReceiptFetching,
          setReversedReceiptID
        }}
      >
        {props.children}
      </GeneralStateDispatchContext.Provider>
    </GeneralStateContext.Provider>
  );
} // PC-03 This component will be providing all the required state variables for all components

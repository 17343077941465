/* eslint-disable */
import { AxiosResponse } from 'axios';
import { APIError } from '../types/types';
import { AccountManagementURLParam, CONTENT_TYPE } from '../constants/constants';
import { calculatePaymentModule, getNonInvSalesOptions } from '../api/user';
import moment from 'moment';
import { AgreementDetails, Customer, MiscellaneousItemData, PaymentMethod, PaymentScheduleReferences } from '../refactoredComponents/interface/paymentInfoInterface';
import { ChargeOffDetails, DropDownArray, NonInventorySaleTypeArray, OtherIncomePayload } from '../refactoredComponents/interface/commonInterface';
import CONSTANTS from '../refactoredComponents/constants/constant';
import { AcimaOtherDataInterface, DropDownResponse, NonInventorySaleDropDownResponse, XboxDataObj } from '../refactoredComponents/interface/nonInventorySaleInterface';
import { FeatureFlags, PaymentSchedule } from '../refactoredComponents/interface/contextInterface';
import { CustomerAccountPayloadInterface } from '../refactoredComponents/interface/addCheckInterface';
import { AgreementPaymentEntity, OtherFee } from '../refactoredComponents/interface/acceptPaymentInterface';
import calculationModulePayloadFormationUpdate from '../refactoredComponents/microfrontends/CommonModulePayloadFormationUpdate';
import { storeInfo } from '../refactoredComponents/interface/paymentSideBarInterface';

export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getContentType = (fileName: string) => {
  let result;
  if (fileName && typeof fileName === 'string') {
    const splits = fileName.split('.');
    const extension = splits[splits.length - 1] as string;
    result = CONTENT_TYPE[extension];
  }
  return result || CONTENT_TYPE.pdf;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};

export const isLandedFromAM = (pathName: string) =>
  pathName.includes(AccountManagementURLParam);

export const AppendEllipse = (str: any, max: number, add: any) => {
  add = add || '...';
  return typeof str === 'string' && str.length > max
    ? str.substring(0, max) + add
    : str;
}; // PC - 02 Here all the common methods are defined and exported to thr components.

export const selectAppender = (totalValue: any) => {
  return [
    { description: 'Select reason code', referenceCode: 'SELECT' },
  ].concat(totalValue);
}; // This method will be appending the select option in the reverse reason drop down.

export const b64toBlob = (
  b64Data: any,
  contentType = 'application/pdf',
  sliceSize = 512
) => {
  const byteCharacters = atob(b64Data);
  const byteArrays: any = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, { type: contentType });
}; // This method will be converting base 64 to blob URL.

export const isStringWithDigits = (n: number, x: unknown) => typeof x === 'string' && (new RegExp(`^\\d{${n}}$`)).test(x);


export const customerAccountObjectFormation = (
  actionType: string,
  accountType: string,
  amount: string,
  agreementId?: string
) => {
  return {
    actionType: actionType,
    accountType: accountType,
    storeNumber: String(window.sessionStorage.getItem("storeNumber")),
    amount: Number(amount).toFixed(2),
    agreementId: agreementId ? agreementId : undefined
  };
};


export const customerAccountPayloadFormation = (customerInfo: Customer, textToPayRemainingSuspense?: string) => {
  let customerOrderPayload: CustomerAccountPayloadInterface[] = [];
  const amountUsed = customerInfo.amountUsed;
  const change = customerInfo.change;

  const addPayload = (actionType: string, accountType: string, amount: string) => {
    if (amount != undefined && Number(amount) > CONSTANTS.ZERO_NUMBER) {
      customerOrderPayload.push(customerAccountObjectFormation(actionType, accountType, amount));
    }
  };

  addPayload(CONSTANTS.ACCOUNT_DEBITE, CONSTANTS.ACCOUNT_TYPE_WEBLEAD, amountUsed.webLeadUsed);
  addPayload(CONSTANTS.ACCOUNT_DEBITE, CONSTANTS.CAPITAL_COA, amountUsed.coaUsed);
  addPayload(CONSTANTS.ACCOUNT_DEBITE, CONSTANTS.ACCOUNT_TYPE_SIPS, amountUsed.ipSuspenseUsed);
  addPayload(CONSTANTS.ACCOUNT_CREDIT, CONSTANTS.ACCOUNT_TYPE_CR, amountUsed.carryRent);

  if (change?.type !== undefined && !textToPayRemainingSuspense) {
    switch (change.type) {
      case CONSTANTS.CAPITAL_SUSPENSE:
        addPayload(CONSTANTS.ACCOUNT_CREDIT, CONSTANTS.ACCOUNT_TYPE_SUSP, change.amount);
        break;
      case CONSTANTS.CAPITAL_CREDIT:
        addPayload(CONSTANTS.ACCOUNT_CREDIT, CONSTANTS.CAPITAL_COA, change.amount);
        break;
    }
  }
  else if (change?.type !== undefined && textToPayRemainingSuspense) {
    addPayload(CONSTANTS.ACCOUNT_CREDIT, CONSTANTS.CAPITAL_COA, change.amount);
  }
  return customerOrderPayload;


}

export const tenderDetailsPayloadFormation = (customerInfo: Customer, textToPayRemainingSuspense?: string) => {
  let tenderDetailsPayload: any[] = []
  if (Number(customerInfo.amountUsed.tenderDetails.cash.amount) > CONSTANTS.ZERO_NUMBER) {
    tenderDetailsPayload.push({
      type: CONSTANTS.TENDER_TYPE_CASH,
      amount: Number(customerInfo.amountUsed.tenderDetails.cash.amount).toFixed(2),
    })
  }

  if (customerInfo.amountUsed.tenderDetails.checkDetails.length > 0) {
    customerInfo.amountUsed.tenderDetails.checkDetails.forEach((x) => {
      tenderDetailsPayload.push({
        type: CONSTANTS.TENDER_TYPE_CHECK,
        amount: Number(x.amount).toFixed(2),
        tenderNumber: x.checkNumber,
        checkType: x.typeOfCheck,
        checkWrittenBy: x.writtenBy,
        checkWrittenTo: x.writtenTo
      })

    })
  }

  if (customerInfo.amountUsed.tenderDetails.moneyOrderDetails.length > 0) {
    customerInfo.amountUsed.tenderDetails.moneyOrderDetails.forEach((x) => {
      tenderDetailsPayload.push({
        type: CONSTANTS.TENDER_TYPE_MO,
        amount: Number(x.amount).toFixed(2),
        tenderNumber: x.moneyOrderNumber,
        purchaser: x.purchaser,
        issuer: x.issuer
      })

    })
  }

  if (customerInfo.change.type == "change" && Number(customerInfo.change.amount) > CONSTANTS.ZERO_NUMBER && !textToPayRemainingSuspense) {
    tenderDetailsPayload.push({
      type: CONSTANTS.ACCOUNT_TYPE_CHG,
      amount: customerInfo.change.amount
    })
  }

  return tenderDetailsPayload;

}

export const suspensePayloadFormation = (selectedNonClubAgreements: AgreementDetails[]) => {
  let customerOrderPayload: CustomerAccountPayloadInterface[] = [];
  selectedNonClubAgreements.forEach((el) => {
    if (el.suspenseDetails?.suspUse != undefined && Number(el.suspenseDetails.suspUse) > CONSTANTS.ZERO_NUMBER) {
      customerOrderPayload.push(customerAccountObjectFormation(CONSTANTS.ACCOUNT_DEBITE, CONSTANTS.ACCOUNT_TYPE_SUSP, el.suspenseDetails.suspUse, el.agreementId))
    }
    if (el.suspenseDetails?.suspAdd != undefined && Number(el.suspenseDetails.suspAdd) > CONSTANTS.ZERO_NUMBER) {
      customerOrderPayload.push(customerAccountObjectFormation(CONSTANTS.ACCOUNT_CREDIT, CONSTANTS.ACCOUNT_TYPE_SUSP, el.suspenseDetails.suspAdd, el.agreementId))
    }

  })

  return customerOrderPayload;
}
interface transferSuspensePayload {
  balance: string;
  agreementId: string;
  isTransferToCoa: string | undefined;

}
export const transferSuspensePayloadFormation = (selectedNonClubAgreements: AgreementDetails[]) => {
  let transferSuspensePayload: transferSuspensePayload[] = [];
  selectedNonClubAgreements?.forEach((el) => {
    if ((el?.suspenseDetails?.transferSusAdd != undefined && el?.suspenseDetails?.transferSusAdd != CONSTANTS.ZREO_TWO_DECIMAL_STRING) || (el.suspenseDetails?.transferSusUse != undefined && el.suspenseDetails?.transferSusUse != CONSTANTS.ZREO_TWO_DECIMAL_STRING)) {
      transferSuspensePayload.push({
        balance: Number(Number(el.suspenseAmount) + Number(el.suspenseDetails.transferSusAdd) - Number(el.suspenseDetails.transferSusUse)).toFixed(2),
        agreementId: el.agreementId,
        isTransferToCoa: el.suspenseDetails.transferedToCOA ? "Y" : undefined
      })

    }
  })
  return transferSuspensePayload
}

export const clubPayloadFormation = (selectedClub: AgreementDetails[]) => {
  let clubPayload: any = [];
  selectedClub.forEach((el) => {
    clubPayload.push({
      customerClubId: String(el.agreementId),
      isZeroPayment: el.isZeroPayment == true || el?.isReturnAgreement ? 'Y' : undefined,
      editedSchedule: el.editSchedule != CONSTANTS.EMPTY_STRING ? el?.editSchedule : undefined,
      adjustedDueDate: el.isAdjustDuedateEdit
        ? moment(el.nextDueDate).format('YYYY-MM-DD')
        : undefined,
    });
  })

  return clubPayload
}
export const agreementPayloadFormation = (selectedNonClubAgreements: AgreementDetails[]) => {
  let agreementPaymentPayload: AgreementPaymentEntity[] = []
  selectedNonClubAgreements.forEach((el: AgreementDetails) => {

    agreementPaymentPayload.push({
      agreementId: el.agreementId,
      editedSchedule: el?.isEditScheduleEdited ? el?.editSchedule : undefined,
      adjustedDueDate: el?.isAdjustDuedateEdit && el.nextDueDate !== CONSTANTS.PIF && el.nextDueDate !== CONSTANTS.SAC && el.nextDueDate !== CONSTANTS.EPO && el.nextDueDate && el.nextDueDate != CONSTANTS.EMPTY_STRING
        ? moment(el.nextDueDate).format('YYYY-MM-DD')
        : undefined,
      freeTime:
        el?.freeTime == null
          ? undefined
          : {
            type: el?.freeTime?.freeTimeType,
            freeDays: String(el?.freeTime?.freeTimeDays),
            waiveOptionalService: el?.freeTime?.isOptServiceWaived
              ? CONSTANTS.STATUS_YES
              : CONSTANTS.STATUS_NO,
          },
      isZeroPayment: el.isZeroPayment == true || el?.isReturnAgreement ? CONSTANTS.STATUS_YES : undefined,
      activatePolicy: el.isPolicyActionPerformed == CONSTANTS.STATUS_YES && el.coverageStatus != CONSTANTS.STATUS_YES ? CONSTANTS.STATUS_ACTIVE : el.isPolicyActionPerformed == CONSTANTS.STATUS_NO && el.coverageStatus != CONSTANTS.STATUS_NO ? 'D' : undefined,
      activateLdw: el.isLDWActionPerformed == CONSTANTS.STATUS_YES && el.coverageStatus != CONSTANTS.STATUS_YES ? CONSTANTS.STATUS_ACTIVE : el.isLDWActionPerformed == CONSTANTS.STATUS_NO && el.coverageStatus != CONSTANTS.STATUS_NO ? 'D' : undefined,
      epoPayment: el?.acceptEpoFlag == CONSTANTS.ONE_NUMBER || (el?.isAdjustDuedateEdit && (el.nextDueDate == CONSTANTS.SAC || el.nextDueDate == CONSTANTS.EPO)) ? CONSTANTS.STATUS_YES : undefined,
      carryLateFeeAmount:
        Number(el.carriedLateFee) > CONSTANTS.ZERO_NUMBER
          ? Number(el.carriedLateFee).toFixed(2)
          : undefined,
      waiveLateFeeAmount:
        Number(el.waivedLateFee) > CONSTANTS.ZERO_NUMBER
          ? Number(el.waivedLateFee).toFixed(2)
          : undefined,
      epoDiscount:
        el.acceptEpoFlag == CONSTANTS.ONE_NUMBER
          ? {
            epoDiscount: el?.isAcceptEPODiscounts?.epoDiscount,
            pastDueDiscount: el?.isAcceptEPODiscounts?.pastDueDiscount,
            noteDiscount: el?.isAcceptEPODiscounts?.noteDiscount
          }
          : undefined,
    });

  })
  return agreementPaymentPayload

}


export const generateMiscPaymentPayload = async (miscelaneous: AgreementDetails[], customerInfo: Customer, storeInfo) => {
  debugger
  let miscPaymentPayload: any = [];
  miscelaneous.forEach((el) => {
    let type: string = CONSTANTS.EMPTY_STRING;
    let amount: string = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    let tax: string = CONSTANTS.ZREO_TWO_DECIMAL_STRING;
    let otherFee: OtherFee[] = [];
    let misItemData: MiscellaneousItemData | AcimaOtherDataInterface | XboxDataObj | undefined;
    let receiptReason: string | undefined;
    let agreementId: string | undefined;
    let receiptTransactionId: string | undefined;
    let customerAccountId: string | undefined;
    let inventoryId: string | undefined;

    switch (el.otherIncomeType) {
      case CONSTANTS.CELL_PHONE:
        type = CONSTANTS.WIREACT;
        tax = Number(el.totalTax).toFixed(2);
        amount = (Number(el.totalDueAmount) - Number(el.totalTax)).toFixed(2);
        misItemData = {
          terminalId: el?.miscellaneousItemInfo?.terminalId ?? CONSTANTS.EMPTY_STRING,
          sessionId: el?.miscellaneousItemInfo?.sessionId ?? CONSTANTS.EMPTY_STRING,
          actionId: el?.miscellaneousItemInfo?.actionId ?? CONSTANTS.EMPTY_STRING,
          saleId: el?.miscellaneousItemInfo?.saleId ?? CONSTANTS.EMPTY_STRING,
          requestId: el?.miscellaneousItemInfo?.requestId ?? CONSTANTS.EMPTY_STRING,
          sequenceNumber: el?.miscellaneousItemInfo?.apiCalc ?? CONSTANTS.EMPTY_STRING,
          txnId: el?.miscellaneousItemInfo?.txnId ?? CONSTANTS.EMPTY_STRING,
          productId: el?.miscellaneousItemInfo?.productId ?? CONSTANTS.EMPTY_STRING,
          amount: el?.miscellaneousItemInfo?.amount ?? CONSTANTS.EMPTY_STRING,
          shopId: el?.miscellaneousItemInfo?.shopId ?? CONSTANTS.EMPTY_STRING,
          cashier: el?.miscellaneousItemInfo?.cashier ?? CONSTANTS.EMPTY_STRING,
          consumerDiscount: el?.miscellaneousItemInfo?.consumerDiscount || CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          serviceFee: el?.miscellaneousItemInfo?.serviceFee || CONSTANTS.ZREO_TWO_DECIMAL_STRING,
          productName: String(el?.miscellaneousItemInfo?.type),
          saleAmount: String(el?.miscellaneousItemInfo?.salesPrice),
          mobileNumber: String(el?.miscellaneousItemInfo?.phoneNumber),
          carrier: String(el?.miscellaneousItemInfo?.carrierName),
          pricePlan: String(el?.miscellaneousItemInfo?.description),
          totalSale: String(el?.miscellaneousItemInfo?.totalSales),
          simNumber: String(el?.miscellaneousItemInfo?.simNumber),
          customData: el?.miscellaneousItemInfo?.data,
        };
        if (Number(el?.miscellaneousItemInfo?.nineOneOneFee) > CONSTANTS.ZERO_NUMBER) {
          otherFee.push({
            type: CONSTANTS.CELL911,
            amount: Number(el.miscellaneousItemInfo?.nineOneOneFee).toFixed(2)
          });
          amount = (Number(amount) - Number(el.miscellaneousItemInfo?.nineOneOneFee)).toFixed(2);
        }
        break;

      case CONSTANTS.RENTAL_ITEM_SALE:
        type = CONSTANTS.CASHSALE
        amount = (Number(el.totalDueAmount) - Number(el.totalTax) - Number(el.deliveryAmount)).toFixed(2)
        inventoryId = String(el.agreementId)
        tax = (Number(el.totalTax) - Number(el.risDeliveryfeeTax)).toFixed(2)
        if (Number(el?.deliveryAmount) > CONSTANTS.ZERO_NUMBER) {
          otherFee.push({
            type: CONSTANTS.DELIVFEE,
            tax: Number(el?.risDeliveryfeeTax) > CONSTANTS.ZERO_NUMBER ? Number(el.risDeliveryfeeTax).toFixed(2) : undefined,
            amount: Number(el.deliveryAmount).toFixed(2)
          })
        }

        break;

      case CONSTANTS.NSF_CHECK:
        type = CONSTANTS.NSF;
        amount = (
          Number(el.totalDueAmount) -
          Number(el.totalTax) -
          Number(el.nsfFee)
        ).toFixed(2);

        receiptTransactionId = String(el.agreementId);
        customerAccountId = String(el.customerAccountId);
        otherFee.push({
          amount: Number(el.nsfFee).toFixed(2),
          type: CONSTANTS.NSFFEE,
          tax: Number(el.totalTax).toFixed(2)
        });
        break;

      case CONSTANTS.CREDIT_CARD_CHARGE_BACK:
        type = CONSTANTS.CCCB;
        amount = (Number(el.totalDueAmount) - Number(el.totalTax) - Number(el.nsfFee)).toFixed(2);
        // tax = Number(el.totalTax).toFixed(2);
        receiptTransactionId = String(el.agreementId);
        customerAccountId = String(el.customerAccountId);
        otherFee.push({
          amount: Number(el.nsfFee).toFixed(2),
          type: CONSTANTS.CCCBFEE,
          tax: Number(el.totalTax).toFixed(2)
        });

        break;

      case CONSTANTS.BACK_RENT:
        type = CONSTANTS.BR;
        amount = (Number(el.totalDueAmount) - Number(el.totalTax)).toFixed(2);
        tax = Number(el.totalTax).toFixed(2);

        break;
      case CONSTANTS.RESTITUTION:
        receiptReason = el.reasonRefcode
        type = CONSTANTS.CO
        amount = (Number(el.totalDueAmount) - Number(el.totalTax)).toFixed(2)
        tax = Number(el.totalTax).toFixed(2)
        agreementId = String(el.agreementId)
        break;
      case CONSTANTS.XBOX_GIFT_CARD:
        console.log("inside xbox gift card")
        type = CONSTANTS.XBGFTCRD
        amount = (Number(el.totalDueAmount) - Number(el.totalTax)).toFixed(2)
        tax = Number(el.totalTax).toFixed(2)
        receiptReason = el.agreementDesc
        break;

      case CONSTANTS.XBOX_GIFT_MEMBERSHIP:
        console.log("XBOX_GIFT_MEMBERSHIP inside")
        type = CONSTANTS.XBMEMSHIP
        amount = (Number(el.totalDueAmount) - Number(el.totalTax)).toFixed(2)
        tax = Number(el.totalTax).toFixed(2)
        receiptReason = el.agreementDesc
        break
      case CONSTANTS.NON_INVENTORY_SALE:
        console.log("NON_INVENTORY_SALE")
        type = el.agreementDesc.includes(CONSTANTS.ACIMA_RECOVERY_SETTLEMENT) ? CONSTANTS.ACIMARECOVERY : CONSTANTS.NONINVCASH
        amount = (Number(el.totalDueAmount) - Number(el.totalTax) - Number(el.deliveryAmount)).toFixed(2)
        tax = (Number(el.totalTax) - Number(el.deliveryAmountTax)).toFixed(2)
        receiptReason = el.agreementDesc
        if (Number(el.deliveryAmount) > CONSTANTS.ZERO_NUMBER) {
          otherFee.push({
            type: CONSTANTS.DELIVFEE,
            amount: Number(el.deliveryAmount).toFixed(2),
            tax: Number(el.deliveryAmountTax).toFixed(2)
          })
        }
        break

    }
    miscPaymentPayload.push({
      type,
      amount,
      tax,
      receiptReason,
      agreementId,
      receiptTransactionId,
      customerAccountId,
      inventoryId,
      otherFee: otherFee.length === CONSTANTS.ZERO_NUMBER ? undefined : otherFee,
      itemData: misItemData,
      receiptText: undefined
    })
  })

  if (customerInfo.rsdAgreementInfo && customerInfo.rsdAgreementInfo.length > 0) {
    customerInfo.rsdAgreementInfo.forEach((rsdAgreement) => {
      miscPaymentPayload.push({
        type: CONSTANTS.SECDEP,
        amount: String(rsdAgreement.amountToCollect),
        agreementId: String(rsdAgreement.agreementId),
      });
    });
  }
  const donationAmount = Number(customerInfo.userEnteredDonationAmount);
  if (donationAmount > 0) {
    const storeDetails: storeInfo[] =
      storeInfo?.storeDetails?.filter(
        (store) =>
          store.storeNumber == sessionStorage.getItem(CONSTANTS.STORE_NUMBER)
      ) || [];
    let reason: any = ""
    switch (storeDetails[0]?.donationType) {
      case 'BGC':
        reason = "Boys and Girls Club";
        break;
      case 'STON':
        reason = "Fill The Fridge";
        break;
      case 'BBS':
        reason = "Big Brother/Big Sister";
        break;
    }
    if (storeDetails[0]?.donationDesc.length > 250 && reason == "") {
      reason = storeDetails[0]?.donationDesc.slice(0, 250)
    }
    miscPaymentPayload.push({
      type: CONSTANTS.DONATION + String(storeDetails[0]?.donationType),
      amount: donationAmount.toFixed(2),
      receiptReason: reason == "" ? storeDetails[0]?.donationDesc : reason

    });
  }
  return miscPaymentPayload

}

export const risPaygridFormation = (inventoryDetails) => {
  let obj: AgreementDetails = {} as AgreementDetails;
  if (inventoryDetails) {
    obj = {
      agreementId: inventoryDetails.inventoryId,
      agreementNumber: inventoryDetails.inventoryNumber,
      agreementDesc: inventoryDetails.inventoryDescription,
      agreementType: CONSTANTS.RENTAL_ITEM_SALE,
      totalAmount: Number(Number(inventoryDetails.totalsales) - Number(inventoryDetails.salestax)).toFixed(2),
      totalDueAmount: inventoryDetails.totalsales,
      totalTax: inventoryDetails.salestax,
      agreementRateDetails: {},
      deliveryAmount: inventoryDetails.Deliveryfee == CONSTANTS.SELECT ? CONSTANTS.ZERO_NUMBER : inventoryDetails.Deliveryfee,
      risDeliveryfeeTax: inventoryDetails.DeliverfeeTax == CONSTANTS.EMPTY_STRING || inventoryDetails.DeliverfeeTax == undefined ? 0 : inventoryDetails.DeliverfeeTax,
      inventoryInfo: inventoryDetails,
      extensionAmount: CONSTANTS.ZERO_NUMBER,
      agreementStatus: CONSTANTS.EMPTY_STRING,
      agreementSchedule: CONSTANTS.EMPTY_STRING,
      currentDueDate: CONSTANTS.EMPTY_STRING,
      daysLate: CONSTANTS.ZERO_NUMBER,
      storeNumber: String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)),
      sacDate: CONSTANTS.EMPTY_STRING,
      suspenseAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      epoAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      deliveryAmountTax: inventoryDetails.DeliverfeeTax == CONSTANTS.EMPTY_STRING || inventoryDetails.DeliverfeeTax == undefined ? CONSTANTS.ZERO_NUMBER : inventoryDetails.DeliverfeeTax,
      lateLast10: CONSTANTS.ZERO_NUMBER,
      otherIncomeType: CONSTANTS.RENTAL_ITEM_SALE,
      nextDueDate: CONSTANTS.EMPTY_STRING,
      promoFreeDays: CONSTANTS.ZERO_NUMBER,
      rentPaid: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      lateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      lateFeetax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carriedLateRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      numberOfPaymentsMade: CONSTANTS.ONE_NUMBER,
      deliveryFlag: false,
      selected: true,
      suspenseDetails: {
        suspAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        suspUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        transferSusAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        transferSusUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      },
      isLDW: false,
      isPolicy: false,
      coverageStatus: CONSTANTS.STATUS_NO,
      isCoverageActionPerformed: CONSTANTS.STATUS_NO,
      ldwAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      ldwTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      policyAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      policyTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      isEpp: false,
      switchoutDeliveryStatus: CONSTANTS.EMPTY_STRING,
      taxRate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      isLdwAllowed: false,
      isPolicyAllowed: false,
      ldwPercentage: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      epoTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      policyStatus: false,
      carriedLateAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carriedLateTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carryRentTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carryRentAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      scheduleRate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      isAutopayEnroll: null,
      cardLastFour: null,
      isReturnAgreement: false,
      isItemInService: false,
      isZeroPayment: false,
      openDate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      actualEpo: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      isExchanged: false,
      parentAgreementId: CONSTANTS.EMPTY_STRING,
      cashPrice: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      totalCost: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      pastDueRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      actualPastDueRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      //For accept EPO
      isAcceptEPODiscounts: {
        epoDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        pastDueDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        noteDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      },

    }

  }

  return [obj];

};

export const scheduleReaasonOptionFormation = (schedule: PaymentScheduleReferences[], type: string) => {

  let scheduleReasonOptions: PaymentScheduleReferences[] | PaymentSchedule[];

  scheduleReasonOptions = schedule.filter((x) => x.referenceKey == type);
  if (type == CONSTANTS.AGREEMENT_STATUS_REASON) {
    scheduleReasonOptions = scheduleReasonOptions[0].referenceDetails
      ?.filter((x) => CONSTANTS.CHARGEOFF_REFCODES.includes(x.referenceCode))
      ?.sort(function (obj1: PaymentSchedule, obj2: PaymentSchedule) {
        return obj1?.description.localeCompare(obj2.description);
      });
  }

  return scheduleReasonOptions || []
}
export const otherIncomeResponseFormation = async (payloadRes: OtherIncomePayload[]) => {

  let obj: AgreementDetails;
  let responseArray: AgreementDetails[] = [];
  console.log('payloadRes', payloadRes);
  for (let i = 0; i < payloadRes.length; i++) {
    const totalAmount = Number(payloadRes[i].type == CONSTANTS.BACK_RENT ? payloadRes[i]?.Amount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING : payloadRes[i].amount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING) - Number(payloadRes[i].type == CONSTANTS.RESTITUTION ? payloadRes[i].newTax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING : payloadRes[i].salesTax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING);
    obj = {
      agreementNumber: payloadRes[i]?.agreementNumber ?? CONSTANTS.EMPTY_STRING,
      agreementId: payloadRes[i].type == CONSTANTS.BACK_RENT ? payloadRes[i].agreementNumber ?? CONSTANTS.EMPTY_STRING : payloadRes[i].agreementId ?? CONSTANTS.EMPTY_STRING,
      agreementType: payloadRes[i].type ?? CONSTANTS.EMPTY_STRING,
      agreementDesc: payloadRes[i].type == CONSTANTS.RESTITUTION ? payloadRes[i].reason ?? CONSTANTS.EMPTY_STRING : payloadRes[i].description ?? CONSTANTS.EMPTY_STRING,
      totalDueAmount: payloadRes[i].type == CONSTANTS.BACK_RENT ? payloadRes[i]?.Amount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING : payloadRes[i].amount ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      totalTax: payloadRes[i].type == CONSTANTS.RESTITUTION ? payloadRes[i].newTax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING : payloadRes[i].salesTax ?? CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      extensionAmount: CONSTANTS.ZERO_NUMBER,
      agreementStatus: CONSTANTS.EMPTY_STRING,
      agreementSchedule: CONSTANTS.EMPTY_STRING,
      currentDueDate: CONSTANTS.EMPTY_STRING,
      daysLate: CONSTANTS.ZERO_NUMBER,
      totalAmount: Number(totalAmount).toFixed(2),
      storeNumber: String(window.sessionStorage.getItem(CONSTANTS.STORE_NUMBER)),
      sacDate: CONSTANTS.EMPTY_STRING,
      agreementRateDetails: {},
      suspenseAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      epoAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      deliveryAmount: payloadRes[i]?.deliveryAmount ? payloadRes[i]?.deliveryAmount : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      deliveryAmountTax: payloadRes[i]?.deliveryAmountTax ? payloadRes[i]?.deliveryAmountTax : CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      lateLast10: CONSTANTS.ZERO_NUMBER,
      otherIncomeType: payloadRes[i].type ?? CONSTANTS.EMPTY_STRING,
      miscellaneousItemInfo: payloadRes[i]?.miscellaneousItemInfo ?? {},
      nextDueDate: payloadRes[i].type == CONSTANTS.RESTITUTION ? CONSTANTS.PIF : CONSTANTS.EMPTY_STRING,
      reasonRefcode: payloadRes[i].reasonRefcode ? payloadRes[i].reasonRefcode : CONSTANTS.EMPTY_STRING,
      promoFreeDays: CONSTANTS.ZERO_NUMBER,
      rentPaid: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      lateFee: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      lateFeetax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carriedLateRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      numberOfPaymentsMade: CONSTANTS.ONE_NUMBER,
      deliveryFlag: false,
      selected: true,
      suspenseDetails: {
        suspAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        suspUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        transferSusAdd: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        transferSusUse: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      },
      isLDW: false,
      isPolicy: false,
      coverageStatus: CONSTANTS.STATUS_NO,
      isCoverageActionPerformed: CONSTANTS.STATUS_NO,
      ldwAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      ldwTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      policyAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      policyTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      isEpp: false,
      switchoutDeliveryStatus: CONSTANTS.EMPTY_STRING,
      taxRate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      isLdwAllowed: false,
      isPolicyAllowed: false,
      ldwPercentage: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      epoTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      policyStatus: false,
      carriedLateAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carriedLateTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carryRentTax: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      carryRentAmount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      scheduleRate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      isAutopayEnroll: null,
      cardLastFour: null,
      isReturnAgreement: false,
      isItemInService: false,
      isZeroPayment: false,
      openDate: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      actualEpo: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      miscCounters: payloadRes[i].counter ?? CONSTANTS.ZERO_NUMBER,
      isExchanged: false,
      parentAgreementId: CONSTANTS.EMPTY_STRING,
      cashPrice: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      totalCost: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      pastDueRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      actualPastDueRent: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      //For accept EPO
      isAcceptEPODiscounts: {
        epoDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        pastDueDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
        noteDiscount: CONSTANTS.ZREO_TWO_DECIMAL_STRING,
      },
    }
    responseArray.push(obj);

  }

  return responseArray

}

//Accept EPO
export const calculateTotal = (
  amountWithoutTax: number,
  tax: number,
  suspense: number
): number => {
  return amountWithoutTax + tax - suspense;
};

export const calculateTax = (amountWithoutTax: number, taxRate: number): number => {
  return amountWithoutTax * taxRate;
};

export const getCustDetail = (props) => {
  let arr: any = [];
  if (props) {
    let obj: any = {
      customerId: props?.customerId,
      customerName:
        props?.customerDetails[0].firstName +
        ' ' +
        props?.customerDetails[0].lastName,
      phoneNumber:
        props?.customerDetails[0].phoneNumber,
      emailId: props?.customerDetails[0].emailAddress,
    };
    arr.push(obj);
  }
  console.log('arr', arr);
  return arr;
  // setcustomerData(arr)
};

export const cashSaleTypeFormation = (description: string, data: DropDownResponse[]) => {
  const dropArray = data.filter((x) => x.description == description);
  return {
    label: dropArray[0].description,
    value: dropArray[0].transactionType ?? CONSTANTS.EMPTY_STRING
  }
}
// const {nonInventorySaleOptions,setNonInventorySaleOptions}=useContext(agreementContext);
export const typeDropDown = (featureFlags: FeatureFlags, data: DropDownResponse[]) => {
  let formAvailableSalesType: DropDownArray[] = [{ label: CONSTANTS.SELECT, value: CONSTANTS.SELECT }];

  let typeArray: NonInventorySaleTypeArray[] = [
    { type: CONSTANTS.XBOX_MEMBERSHIP_TYPE, flag: featureFlags.XboxMembership ?? CONSTANTS.ZERO_STRING },
    { type: CONSTANTS.XBOX_GIFT_CARD, flag: featureFlags.XboxGiftCard ?? CONSTANTS.ZERO_STRING },
    { type: CONSTANTS.OTHER, flag: featureFlags.OtherSale ?? CONSTANTS.ZERO_STRING },
    {
      type: CONSTANTS.ACIMA_RECOVERY_SETTLEMENT,
      flag: featureFlags.AcimaRecovery ?? CONSTANTS.ZERO_STRING,
    },
    { type: CONSTANTS.CELL_PHONE_ACTIVATION, flag: featureFlags.CashSale ?? CONSTANTS.ZERO_STRING },
  ];

  const configValidation = (type: string, data: DropDownResponse[], featureFalg: string) => {
    if (featureFalg == CONSTANTS.ONE_STRING) {
      formAvailableSalesType.push(cashSaleTypeFormation(type, data));
    }
  };
  typeArray.forEach((x) => {
    configValidation(x.type, data, x.flag);
  });
  return formAvailableSalesType;
};

export const calculateModule = async (
  customerId: string,
  storeNumber: string,
  respData: ChargeOffDetails[] | AgreementDetails[]
) => {
  const commonModulePayload = await calculationModulePayloadFormationUpdate({
    customerId: customerId,
    storeNumber: storeNumber,
    checkedAgr: respData,
  });

  let commonModuleServiceRes = await calculatePaymentModule(
    commonModulePayload
  );

  commonModuleServiceRes = commonModuleServiceRes?.data;
  return commonModuleServiceRes;
};

export const xboxDropDownLoad = async (type) => {
  let typeDropDownResp: NonInventorySaleDropDownResponse = await getNonInvSalesOptions({ salesType: type, provider: "" });
  if (typeDropDownResp.status == 200) {
    // setloadType(false);
    let formAvailableSalesType: DropDownArray[] = [{ label: "select", value: "select" }];
    typeDropDownResp.data.map((el) => {
      formAvailableSalesType.push({
        label: el.description,
        value: el.carrierId,
      });
    })
    return formAvailableSalesType
    // setNonInventorySaleOptions({...nonInventorySaleOptions,xBoxProviderValues:formAvailableSalesType});
  }

}



/* eslint-disable prettier/prettier */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-console */
/**
 * SQ_ACS_2
 */
import React, { useContext, useState } from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Box,
  RACButton,
  RACModalCard,
} from '@rentacenter/racstrap';
import CurrencyInput from 'react-currency-input-field';
import { availableCreditsStyle } from '../stylesJS/availableCreditsStyle';
import { agreementContext } from '../context/PaymentInformationContext';
import { CreditsValue } from '../interface/availableCreditsInterface';
import CONSTANTS from '../constants/constant';
import { RefundWebLeadAmount } from '../../api/user';
import { ReactComponent as Alerticon } from './../../assets/images/apiErrorIcon.svg';
import { ReactComponent as TickIcon } from './../../assets/images/TickIcon.svg';

/**
 * SQ_ACS_3
 * @returns
 */
export function AvailableCredits() {
  /**
   * SQ_ACS_4
   */
  const classes = availableCreditsStyle();
  const agreementInfo = useContext(agreementContext);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [messagePopup, setMessagePopup] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<string>('');
  const [message, setMessage] = useState<string>('');

  /**
   * SQ_ACS_5, SQ_ACS_6, SQ_ACS_7, SQ_ACS_8, SQ_ACS_9, SQ_ACS_10, SQ_ACS_11, SQ_ACS_12, SQ_ACS_13
   * @returns
   */
  const bindAvailableCredits = () => {
    return agreementInfo?.customerInfo?.remainingCredits?.map(
      (creditsValue: CreditsValue, index: number) => {
        if (
          (agreementInfo.customerOrderPayment == CONSTANTS.EMPTY_STRING &&
            creditsValue?.storeNumber ===
              sessionStorage.getItem(CONSTANTS.STORE_NUMBER) &&
            creditsValue?.accountBalance > CONSTANTS.ZERO_TWO_DECIMAL_NUMBER &&
            (creditsValue?.bucket === CONSTANTS.CAPITAL_COA ||
              creditsValue?.bucket === CONSTANTS.IP_SUSPENSE ||
              creditsValue?.bucket === CONSTANTS.WEB_LEAD_DEPOSIT)) ||
          (creditsValue?.storeNumber ===
            sessionStorage.getItem(CONSTANTS.STORE_NUMBER) &&
            creditsValue?.accountBalance > CONSTANTS.ZERO_TWO_DECIMAL_NUMBER &&
            creditsValue?.bucket === CONSTANTS.WEB_LEAD_DEPOSIT)
        ) {
          return (
            <>
              <Grid
                item
                key={index}
                className={`${classes.w100} ${classes.floatLeft}`}
              >
                <>
                  <Typography
                    component={'label'}
                    className={`${classes.formLabel} ${classes.w35} ${classes.mt3} ${classes.floatLeft}`}
                  >
                    {creditsValue.bucket}
                  </Typography>
                  <Box
                    component="span"
                    className={`${classes.labeltxtstyle} ${classes.mt3} ${classes.floatLeft} ${classes.accountBalanceCss}`}
                  >
                    $
                    <Box className={classes.displayInline}>
                      {Number(creditsValue.accountBalance).toFixed(2)}
                    </Box>
                  </Box>
                </>
                <Grid
                  item
                  className={`${classes.w42} ${classes.floatRight} ${classes.mt2}`}
                >
                  <Grid item className={`${classes.inputgroup} ${classes.mb3}`}>
                    <span className={classes.inputgrouptext}>$</span>
                    <CurrencyInput
                      className={`${classes.formcontrol3} ${classes.textend}`}
                      type="text"
                      disabled={true}
                      id="WL-Deposit"
                      defaultValue={Number(creditsValue.accountBalance).toFixed(
                        2
                      )}
                    />
                  </Grid>
                </Grid>
                {agreementInfo.isRefundWebleadEnabled && 
                  creditsValue?.bucket === CONSTANTS.WEB_LEAD_DEPOSIT ? (
                  <Grid className={`${classes.buttonAlignment}`}>
                    <RACButton
                      variant="contained"
                      color="primary"
                      className={`${classes.me3} ${classes.refundButtonMargin}`}
                      onClick={() => {
                        agreementInfo.setOpenRefundWebloadPopup(true);
                      }}
                    >
                      Refund Weblead
                    </RACButton>
                  </Grid>
                ) : null}
              </Grid>
            </>
          );
        }
      }
    );
  };

  const refundWebleadAmount = async () => {
    setButtonLoader(true);
    const refundCustomerWebLeadAmount =
      agreementInfo.refundWebleadPayloadDetails &&
      agreementInfo.refundWebleadPayloadDetails.map((element: any) => {
        const payload = {
          firstName: agreementInfo.customerInfo.customerDetails[0].firstName,
          lastName: agreementInfo.customerInfo.customerDetails[0].lastName,
          amount: element.paymentInfo.balanceAmount,
          originalExtTransactionId: element.paymentInfo.externalTransactionId,
          customerId: element?.orderNumber,
          cardType: element.paymentInfo.cardType,
          paymentType: element.paymentInfo.paymentType,
          globalCustomerId:
            agreementInfo.customerInfo.customerDetails[0].globalCustomerId,
        };
        return RefundWebLeadAmount(payload);
      });
    console.log('refundCustomerWebLeadAmount', refundCustomerWebLeadAmount);
    if (refundCustomerWebLeadAmount) {
      const refundWebleadResponse = await Promise.all(
        refundCustomerWebLeadAmount
      );
      console.log('refundWebleadResponse', refundWebleadResponse);
      const successApiCallStatus: any = [];
      setButtonLoader(false);
      agreementInfo.setOpenRefundWebloadPopup(false);
      refundWebleadResponse.forEach((element: any) => {
        if (element.statusCode == 'A') {
          successApiCallStatus.push(true);
        } else {
          successApiCallStatus.push(false);
        }
      });

      if (successApiCallStatus.every((status) => status === true)) {
        setMessageType('SUCCESS');
        setMessage('Refund Completed Successfully');
        setMessagePopup(true);
      } else if (
        successApiCallStatus?.length > 1 &&
        successApiCallStatus.some((status) => status === false)
      ) {
        setMessageType('FAILED');
        setMessage('Refund was partially completed for this customer.');
        setMessagePopup(true);
      } else if (successApiCallStatus.some((status) => status === false)) {
        setMessageType('ERROR');
        setMessage('An error occurred while processing the Weblead refund.');
        setMessagePopup(true);
      }
    }
  };

  const webLeadRefundPopup = () => {
    return (
      <>
        <Grid>
          <Grid style={{ marginBottom: '5%' }}>
            <Typography
              style={{
                fontSize: '18px',
                textAlign: 'center',
                fontFamily: 'revert',
                lineHeight: '32px',
              }}
            >
              Do you want to refund the deposite for this customer?
            </Typography>
          </Grid>
          <Grid style={{ textAlign: 'center', marginBottom: '2%' }}>
            <RACButton
              variant="outlined"
              color="primary"
              style={{ marginRight: '2%' }}
              onClick={() => {
                agreementInfo.setOpenRefundWebloadPopup(false);
              }}
              disabled={buttonLoader}
            >
              No
            </RACButton>
            <RACButton
              variant="contained"
              color="primary"
              style={{ marginLeft: '2%' }}
              onClick={() => {
                refundWebleadAmount();
              }}
              loading={buttonLoader}
            >
              Yes
            </RACButton>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleMessageCompletion = () => {
    if (messageType == 'SUCCESS') {
      setMessagePopup(false);
      const updatedRemainingCredits =
        agreementInfo.customerInfo?.remainingCredits?.map(
          (creditsValue: CreditsValue) => {
            if (
              creditsValue?.storeNumber ===
                sessionStorage.getItem(CONSTANTS.STORE_NUMBER) &&
              creditsValue?.accountBalance >
                CONSTANTS.ZERO_TWO_DECIMAL_NUMBER &&
              creditsValue?.bucket === CONSTANTS.WEB_LEAD_DEPOSIT
            ) {
              return {
                ...creditsValue,
                bucket: '',
              };
            }
            return creditsValue;
          }
        );
      agreementInfo.setCustomerInfo((prevState) => ({
        ...prevState,
        remainingCredits: updatedRemainingCredits,
      }));
    } else if (messageType == 'FAILED') {
      window.location.reload();
    } else {
      setMessagePopup(false);
    }
  };

  const popupContent = () => (
    <Grid container>
      <Grid item md={12} style={{ textAlign: 'center' }}>
        {messageType == 'SUCCESS' ? <TickIcon /> : <Alerticon />}
        <Typography
          variant={'body2'}
          style={{
            fontFamily: 'OpenSans-bold',
            fontSize: '16px',
            letterSpacing: 0,
            marginBottom: '25px',
            marginTop: '15px',
          }}
        >
          {message}
        </Typography>

        <RACButton
          variant="contained"
          color="primary"
          id="assignAlertBtn"
          onClick={() => {
            handleMessageCompletion();
          }}
        >
          ok
        </RACButton>
      </Grid>
    </Grid>
  );

  /**
   * SQ_ACS_14 - SQ_ACS_18
   */
  return (
    <>
      <Grid container data-testid="availcreditid">
        <Grid
          item
          className={`${classes.w100} ${classes.floatLeft} ${classes.mb2}`}
        >
          <Typography
            variant="h6"
            className={`${classes.floatLeft} ${classes.mt2} ${classes.raccollg8} ${classes.title}`}
          >
            Available Credit
          </Typography>
        </Grid>

        <Grid item className={`${classes.w100} ${classes.floatLeft}`}>
          <Card
            className={`${classes.card} ${classes.w100} ${classes.floatLeft} ${classes.p3}`}
            style={{ marginBottom: '20px' }}
          >
            <CardContent className={classes.p0}>
              {bindAvailableCredits()}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <RACModalCard
        isOpen={agreementInfo.openRefundWebLeadPopup}
        maxWidth="xs"
        data-testid="webLeadPopup"
        onClose={() => {
          agreementInfo.setOpenRefundWebloadPopup(false);
        }}
        closeIcon={true}
        // eslint-disable-next-line react/no-children-prop
        children={webLeadRefundPopup()}
        className={classes.title}
        borderRadius="15px"
      />
      <RACModalCard
        borderRadius="20px"
        isOpen={messagePopup}
        maxWidth="xs"
        closeIcon={false}
        onClose={() => {
          setMessagePopup(false);
        }}
        children={popupContent()}
      />
    </>
  );
}
